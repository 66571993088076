import { IAllowances } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../../client';
import {
  AllowancesParamsType,
  AllowancesType,
  CryptoDepositLimitParamType,
  CryptoDepositLimitType,
  DefaultAllowanceType,
  NextTransactionType,
} from '../model';

class AllowancesApi extends HttpClient implements IAllowances {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  /**
   * @returns Promise<AllowancesType>
   */
  public getAllowances = async (params: AllowancesParamsType) =>
    await this.instance.get<HttpErrorType, AllowancesType>('/allowance/allowances', { params });

  public getNextTransaction = async (params: AllowancesParamsType) =>
    await this.instance.get<HttpErrorType, NextTransactionType>('/allowance/next-transaction', {
      params,
    });

  public getCryptoDepositLimits = async (params: Partial<CryptoDepositLimitParamType>) =>
    await this.instance.get<HttpErrorType, CryptoDepositLimitType[]>('/allowance/crypto-deposit-limits', {
      params,
    });

  public getDefaultAllowances = async () =>
    await this.instance.get<HttpErrorType, DefaultAllowanceType[]>('/allowance/allowances/default');
}

export default AllowancesApi;
