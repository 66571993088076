import { useEffect, useState } from 'react';

import { Block, Overflow, Tab } from '@bilira-org/design';
import { getIsInternalTransferType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import TransactionDetailModal from '@Components/transaction/modal/TransactionDetailModal';
import { TRANSACTION_HISTORY_LIMIT } from '@Libs/config';
import AllTransactionsCard from '@Modules/transactios/components/components/AllTransactionsCard';

import Filters, { VariantType } from './components/Filters';
import { initialFiltersState } from './data';
import { getDefaultType } from './helpers/getDefaultType';
import useTransactionTable from './hooks/useTransactionTable';
import TransactionExportModal from './TransactionExport';

const AllTransactionsCardContainer = () => {
  const { t } = useTranslation();
  const {
    setFilters,
    setType,
    modalActive,
    setModalActive,
    tableCallback,
    selectedTransactionRecordInfo,
    data,
    metadata,
    isPending,
    refetch,
    type,
    filters,
  } = useTransactionTable();
  const [variant, setVariant] = useState<VariantType>('deposit-withdraw');
  const [filterLabels, setFilterLabels] = useState<Record<string, string | undefined>>({ date: 'common.all' });

  const resetFilters = (variant: VariantType) => {
    setFilters(initialFiltersState[variant]);
    setFilterLabels({ date: 'common.all' });
  };

  const { state } = useLocation();

  useEffect(() => {
    if (!state) return;

    const { type, direction, status } = state;

    const initialFilters = {
      ...(direction && { direction }),
      ...(status && { status }),
    };

    if (type) {
      if (['otc', 'swap'].includes(type)) {
        setVariant('swap');
      } else if (getIsInternalTransferType(type)) {
        setVariant('other');
      }
      setType(type);
    }

    setFilters(initialFilters);
  }, [setFilters, setType, state]);

  return (
    <>
      <Tab
        variant="bordered"
        headers={[
          { name: t('transactions.deposit-withdraw'), key: 'deposit-withdraw' },
          { name: t('transactions.buy-sell'), key: 'swap' },
          { name: t('transactions.other'), key: 'other' },
        ]}
        selectedKey={variant}
        headerStretch
        testId="customTestId"
        onChange={(index, header) => {
          setVariant(header.key as VariantType);
          setType(getDefaultType(header.key));
          resetFilters(header.key as VariantType);
        }}
      >
        {(headers) => (
          <>
            {headers.map((header, index) => (
              <Tab.Panel key={header.key}>
                <Overflow overflow="x-auto">
                  <Block row gap="lg" mb="lg">
                    <Filters
                      variant={header.key as any}
                      filters={filters}
                      setFilters={(value) => setFilters({ ...value, offset: 0 })}
                      type={type}
                      setType={setType}
                      filterLabels={filterLabels}
                      setFilterLabels={setFilterLabels}
                      onReset={() => resetFilters(variant)}
                    />
                  </Block>
                </Overflow>

                <AllTransactionsCard
                  onPageChange={(page) =>
                    setFilters({ ...filters, offset: page * TRANSACTION_HISTORY_LIMIT - TRANSACTION_HISTORY_LIMIT })
                  }
                  tableCallback={tableCallback}
                  data={data}
                  metadata={metadata}
                  isPending={isPending}
                  type={type}
                />
              </Tab.Panel>
            ))}
          </>
        )}
      </Tab>

      <TransactionDetailModal
        transactionRecordInfo={selectedTransactionRecordInfo}
        open={modalActive}
        onClose={setModalActive}
        refetch={refetch}
      />

      <TransactionExportModal
        defaultFilters={filters}
        defaultType={type}
        defaultVariant={variant}
        defaultFilterLabels={filterLabels}
      />
    </>
  );
};

export default AllTransactionsCardContainer;
