import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Block, Button, Image, Link, Slider, SliderButtonHandler, Text } from '@bilira-org/design';
import { BlogAnnouncementType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

export interface AnnouncementSliderProps {
  next: () => void;
  prev: () => void;
}

interface Props {
  data?: BlogAnnouncementType[];
}

const AnnouncementSlider = forwardRef<AnnouncementSliderProps, Props>(
  ({ data }, ref?: React.ForwardedRef<AnnouncementSliderProps>) => {
    const { t } = useTranslation();

    const sliderRef = useRef<SliderButtonHandler>(null);

    useImperativeHandle(
      ref,
      () => ({
        next: () => sliderRef?.current?.next(),
        prev: () => sliderRef?.current?.prev(),
      }),
      [],
    );

    return (
      <>
        <Slider
          testId="announcement-slider"
          autoPlay={true}
          ref={sliderRef}
          dataSource={data || []}
          renderItem={(item) => (
            <Slider.Item key={item.id}>
              <Block row justify="between">
                <Block gap="lg" justify="evenly">
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: item.content as string,
                    }}
                  />
                  <div>
                    <Link
                      anchor
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      testId="detailed-info-anchor"
                    >
                      <Button size="md" variant="filled">
                        {t('overview.detailed-info')}
                      </Button>
                    </Link>
                  </div>
                </Block>
                <Image width="size-28" src={item.thumbnail} alt={item.content} skeletonAspectRatio={1} />
              </Block>
            </Slider.Item>
          )}
        />
      </>
    );
  },
);

export default AnnouncementSlider;
