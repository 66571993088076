import { ICdnApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../../client';
import { FeeDataType, LanguageType, MobileLocaleDataType, NetworkETAType } from '../model';

class CdnApi extends HttpClient implements ICdnApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getFeeData = async () =>
    await this.instance.get<HttpErrorType, FeeDataType[]>('/functions/fees-commissions-data');

  public getMobileLocaleData = async (locale: LanguageType) =>
    await this.instance.get<HttpErrorType, MobileLocaleDataType>(`/m/locales/${locale}.json`);

  public getNetworkETA = async () => await this.instance.get<HttpErrorType, NetworkETAType>('/functions/network-eta');
}

export default CdnApi;
