import adjustQuoteAsset from './adjustQuoteAsset';

/**
 * Concatenates the base and quote assets to create a ticker string.
 *
 * @param {string} base - The base asset.
 * @param {string} quote - The quote asset.
 * @returns {string} The concatenated ticker string.
 */
const concatTicker = (base?: string, quote?: string): string => {
  if (!base || !quote) {
    return '';
  }

  const tickerQuote = adjustQuoteAsset(base, quote);
  return `${base}${tickerQuote}`;
};

export default concatTicker;
