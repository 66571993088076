import { isHttpError } from '@bilira-org/react-utils';

import UserQuery from '@Libs/clientInstances/userQuery';

const useCheckHealth = () => {
  const user = UserQuery.useGetUsers();

  const errorTypeUser = isHttpError(user.error);

  return {
    isServerError: errorTypeUser.isServerUnavailable,
    isLoading: !user.isSuccess && !user.isError,
    isLoggedOut: errorTypeUser.isLogout,
  };
};

export default useCheckHealth;
