import BigNumber from 'bignumber.js';

import { AllPricesType, LastPriceType } from '../../../network/price';
import {
  BalanceType,
  BalanceWithQuoteType,
  SwapPairType,
  SwapPairWithBalanceType,
} from '../../../network/stablecoin/model';
import { CoinSymbolType } from '../../../types';
import { concatTicker, orderByPrice, pinItems } from '../../../utils';

type AggregateParams = {
  balanceData: BalanceType[];
  priceData: AllPricesType[];
  quote: CoinSymbolType;
  pinnedAsset?: CoinSymbolType;
};

export const aggregateBalancePrice = ({ balanceData, priceData, quote, pinnedAsset }: AggregateParams) => {
  if (!balanceData) {
    throw new Error('Balance data not.');
  }

  if (!priceData?.length) {
    throw new Error('Price not found.');
  }

  const balancesWithQuote = balanceData.reduce((agg, item) => {
    const assetPrice = priceData.find((price) => price.s === concatTicker(item.asset, quote)) || { p: '0' };
    if (concatTicker(item.asset, quote) === 'TRYBTRY') {
      agg.push({
        ...item,
        id: concatTicker(item.asset, quote),
        quote: quote,
        unit_price: item.unit_price,
        quote_balance: item.balance,
        quote_free_balance: item.free_balance,
        quote_locked_balance: item.locked_balance,
      });
    } else {
      agg.push({
        ...item,
        id: concatTicker(item.asset, quote),
        quote: quote,
        unit_price: assetPrice.p,
        quote_balance: BigNumber(item.balance).times(assetPrice.p).toString(),
        quote_free_balance: BigNumber(item.free_balance).times(assetPrice.p).toString(),
        quote_locked_balance: BigNumber(item.locked_balance).times(assetPrice.p).toString(),
      });
    }
    return agg;
  }, [] as BalanceWithQuoteType[]);

  const sortedBalanceList = orderByPrice<BalanceWithQuoteType>(balancesWithQuote, 'quote_balance', 'desc');
  if (pinnedAsset) {
    return pinItems({
      list: sortedBalanceList,
      pinnedField: 'asset',
      pinnedValue: pinnedAsset,
    });
  }

  return sortedBalanceList;
};

export const getBalanceBy = (
  balanceData: BalanceType[],
  priceData: AllPricesType[],
  base: CoinSymbolType,
  quote: CoinSymbolType,
): BalanceWithQuoteType | null => {
  if (!balanceData) {
    return null;
  }

  const balanceItem = balanceData.find((balance) => balance.asset === base);
  const priceItem = priceData?.find((price) => price.s === concatTicker(base, quote)) || { p: '0' };

  if (!balanceItem) {
    return null;
  }

  return {
    ...balanceItem,
    id: concatTicker(base, quote),
    quote: quote,
    unit_price: priceItem.p,
    quote_balance: BigNumber(balanceItem.balance).times(priceItem.p).toString(),
    quote_free_balance: BigNumber(balanceItem.free_balance).times(priceItem.p).toString(),
    quote_locked_balance: BigNumber(balanceItem.locked_balance).times(priceItem.p).toString(),
  };
};

export const aggregateTotalBalancePrice = (data?: BalanceWithQuoteType[]) => {
  if (!data) {
    return { balance: '0', freeBalance: '0' };
  }

  return data.reduce(
    (agg, item) => {
      return {
        balance: BigNumber(item.balance).times(item.unit_price).plus(agg.balance).toString(),
        freeBalance: BigNumber(item.free_balance).times(item.unit_price).plus(agg.freeBalance).toString(),
      };
    },
    { balance: '0', freeBalance: '0' },
  );
};

/**
 * @deprecated get rid of balance generator.
 * @param data
 * @param lastPrice
 */
export const getQuotePrices = (data: BalanceWithQuoteType[], lastPrice: LastPriceType) => {
  const clonePriceList = JSON.parse(JSON.stringify(data)) as BalanceWithQuoteType[];
  const clonedPrice = clonePriceList.find((price) => price.id === lastPrice.s);
  if (!clonedPrice) {
    return data;
  }

  clonedPrice.unit_price = lastPrice.p;
  clonedPrice.quote_balance = BigNumber(clonedPrice.balance).times(lastPrice.p).toString();
  clonedPrice.quote_free_balance = BigNumber(clonedPrice.free_balance).times(lastPrice.p).toString();
  clonedPrice.quote_locked_balance = BigNumber(clonedPrice.locked_balance).times(lastPrice.p).toString();

  return clonePriceList;
};

export const getSwapPairsWithBalance = (
  swapPairs: SwapPairType[],
  balanceData?: BalanceType[],
): SwapPairWithBalanceType[] => {
  if (!swapPairs) {
    return [] as SwapPairWithBalanceType[];
  }

  const balanceEmptyData = { balance: '0', free_balance: '0', locked_balance: '0' };

  const balancesWithQuote = swapPairs.reduce((agg, item) => {
    const balance = !balanceData
      ? balanceEmptyData
      : balanceData.find((balance) => balance.asset === item.base_asset) || (balanceEmptyData as BalanceType);

    agg.push({
      ...item,
      balance: balance.balance,
      free_balance: balance.free_balance,
      locked_balance: balance.locked_balance,
    });
    return agg;
  }, [] as SwapPairWithBalanceType[]);

  // Later.
  // const sortedBalanceList = orderByPrice<SwapPairWithBalanceType>(balancesWithQuote, 'quote_balance', 'desc');
  return balancesWithQuote;
};

export const filterLowBalances = (data?: BalanceType[]) => {
  return data?.filter((item) => {
    if (item.asset === 'TRYB') {
      return true;
    }

    const isLowBalance = BigNumber(item.price).isLessThan(1);
    return !isLowBalance;
  });
};
