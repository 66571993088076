import { RewardTaskState } from './getRewardTaskState';
import { RewardsTasksType, UserParticipationType } from '../../network/stablecoin/model';

/**
 * Calculates the reward points for a given task based on its type and status.
 *
 * @param {UserParticipationType | RewardsTasksType} task - The task object, which can either be a user participation task or a rewards task.
 * @param {RewardTaskState} taskType - The current state of the task, which determines how points are calculated.
 * @param {boolean} [showOnlyEarnedPoints=false] - Show only earned points, not potential earning points.
 * @returns {string | undefined} - The calculated reward points as a string, or `undefined` if no points are available for the given task type.
 */
export const calculateRewardPoints = (
  task: UserParticipationType | RewardsTasksType,
  taskType: RewardTaskState,
  showOnlyEarnedPoints?: boolean,
): string | undefined => {
  if (taskType === 'toDo' || taskType === 'recurringToDo') {
    return showOnlyEarnedPoints ? '0' : task.point_multiplier;
  }

  return showOnlyEarnedPoints ? (task as UserParticipationType).points_this_season : task.point_multiplier;
};
