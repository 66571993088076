import BigNumber from 'bignumber.js';

import { AssetPairPriceType, PriceFeedData, TradePrice } from '../../network/stablecoin/model';
import { isArrayEmpty } from '../isArrayEmpty';

/**
 * Retrieves the ask or bid price based on the provided direction, quantity, and askBidPrice.
 *
 * @param {string} direction - The direction of the price, either "buy" or "sell".
 * @param {string} quantity - The desired quantity.
 * @param {AssetPairPriceType | PriceFeedData} askBidPrice - The object containing the ask and bid prices.
 * @returns {string | null} - The ask or bid price, or null if the ask or bid prices are empty.
 */
const getAskBidPrice = (
  direction: string,
  quantity: string,
  askBidPrice?: AssetPairPriceType | PriceFeedData,
): string | undefined => {
  if (!askBidPrice || isArrayEmpty(askBidPrice?.bids) || isArrayEmpty(askBidPrice?.asks)) {
    return undefined;
  }

  const prices = direction === 'buy' ? askBidPrice.asks : askBidPrice.bids;

  const predicate = (value: TradePrice) => {
    if (direction === 'buy') {
      return BigNumber(value.price)
        .multipliedBy(value.quantity)
        .isGreaterThanOrEqualTo(quantity || '0');
    }

    return BigNumber(value.quantity).isGreaterThanOrEqualTo(quantity || '0');
  };

  const demonstrativePrice = prices.find(predicate);

  return demonstrativePrice?.price ?? prices[prices.length - 1].price;
};

export default getAskBidPrice;
