import { IRewardsApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../../client';
import {
  DailyLoginType,
  PrerequisitesType,
  SeasonsType,
  TaskHistoryParamsType,
  UserParticipationsType,
  UserPointType,
  UserPrerequisitesType,
  UserTaskHistoryType,
} from '../model/Rewards';

class RewardsApi extends HttpClient implements IRewardsApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  // public getSeasonFeaturedTasks = async () => {
  //   return await this.instance.get<HttpErrorType, RewardsTasksType[]>('/marketing/rewards/season-featured-tasks');
  // };

  public getSeasons = async () => {
    return await this.instance.get<HttpErrorType, SeasonsType>('/marketing/rewards/seasons');
  };

  public getUserParticipation = async () => {
    return await this.instance.get<HttpErrorType, UserParticipationsType>('/marketing/rewards/participations');
  };

  public patchUserParticipation = async () => {
    return await this.instance.patch<HttpErrorType, undefined>('/marketing/rewards/participations');
  };

  public getUserTaskHistory = async (params: TaskHistoryParamsType) => {
    return await this.instance.get<HttpErrorType, UserTaskHistoryType[]>('/marketing/rewards/history', { params });
  };

  // public getLeaderboard = async (seasonId: string) => {
  //   return await this.instance.get<HttpErrorType, LeaderBoardType[]>(`/marketing/rewards/leader-board/${seasonId}`);
  // };

  public getUserPoints = async () => {
    return await this.instance.get<HttpErrorType, UserPointType>(`/marketing/rewards/points`);
  };

  public getUserParticipationPrerequisites = async () => {
    return await this.instance.get<HttpErrorType, UserPrerequisitesType>(
      '/marketing/rewards/user-participation-prerequisites',
    );
  };

  public getParticipationPrerequisites = async () => {
    return await this.instance.get<HttpErrorType, PrerequisitesType>('/marketing/rewards/participation-prerequisites');
  };

  public getDailyLogin = async () => {
    return await this.instance.get<HttpErrorType, DailyLoginType>('/marketing/rewards/daily-login');
  };

  public postDailyLogin = async () => {
    return await this.instance.post<HttpErrorType, undefined>('/marketing/rewards/daily-login');
  };
}

export default RewardsApi;
