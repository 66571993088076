import React from 'react';

import { LayoutV2 } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';

import ConvertCard from './ConvertCard';

const { Content } = LayoutV2;

function Convert() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('market.convert.title')} titleSuffix="subpage" />
      <Content>
        <ConvertCard />
      </Content>
    </>
  );
}

export default Convert;
