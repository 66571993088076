import BigNumber from 'bignumber.js';

import { BuySellCoinType } from '@Components/trade/types';

const calculateConvertPrice = (amount: string, unitPrice: string, direction: BuySellCoinType) => {
  if (amount === '') return null;

  const price1 = BigNumber(direction === 'base' ? '1' : unitPrice);
  const price2 = BigNumber(direction === 'base' ? unitPrice : '1');

  if (price1.isZero() || price2.isZero()) {
    return null;
  }

  const u = BigNumber(price1).dividedBy(BigNumber(price2));
  const price = BigNumber(amount).multipliedBy(u).toString(10);

  return price;
};

export default calculateConvertPrice;
