import { LastPriceType, OrderBookUpdateType, SymbolStatisticsDataType } from '@bilira-org/react-utils';
import { create } from 'zustand';

export interface PriceStoreType {
  lastPrice?: LastPriceType;
  statistics?: SymbolStatisticsDataType;
  orderBook?: OrderBookUpdateType;
  setLiveData: (type: ResponseEventType, data: string) => void;
}

export const usePriceStore = create<PriceStoreType>((set) => ({
  lastPrice: undefined,
  statistics: undefined,
  orderBook: undefined,
  setLiveData: (type: string, data: any) =>
    set(() => {
      switch (type) {
        case 'lp':
          return { lastPrice: data as LastPriceType };

        // case 'obu':
        //   return { orderBook: data as OrderBookUpdateType };

        case 'ss':
          return { statistics: data as SymbolStatisticsDataType };
        default:
          return {};
      }
    }),
}));
