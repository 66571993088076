import React, { useMemo } from 'react';

import { Block, Button, Flex, Hidden, Icon, Meta, Panel, Span, Text } from '@bilira-org/design';
import { AssetPairType, CoinSymbolType, concatTicker, parseAssetPair } from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';

import IconCoin from '@Components/icon/IconCoin';
import SwapQuery from '@Libs/clientInstances/swapQuery';
import { DEFAULT_BUY_SELL_PAIR } from '@Libs/constants';
import useLastLivePrice from '@Libs/hooks/useLastLivePrice';
import useNavigateToMarket from '@Libs/hooks/useNavigateToMarket';
import AssetList from '@Modules/convert/AssetList';
import useAmountCalculation from '@Modules/convert/hooks/useAmountCalculation';
import useAssetPair from '@Modules/convert/hooks/useAssetPair';
import useFilteredLists from '@Modules/convert/hooks/useFilteredLists';
import usePriceUpdate from '@Modules/convert/hooks/usePriceUpdate';
import AssetPairChart from '@Modules/market/detail/components/priceChart';
import RelatedPosts from '@Modules/market/detail/components/relatedPosts/RelatedPosts';

const [defaultBase, defaultQuote] = parseAssetPair(DEFAULT_BUY_SELL_PAIR);

const ConvertCard: React.FC = () => {
  const { t } = useTranslation();

  const { data: assets, isPending } = SwapQuery.useGetSwapPairs();
  const navigate = useNavigateToMarket();

  const initialLists = useMemo(() => {
    if (!assets) return { baseList: [], quoteList: [] };

    const baseSet = new Set<string>();
    const quoteSet = new Set<string>();

    assets.forEach(({ base_asset: baseAsset, quote_asset: quoteAsset }) => {
      baseSet.add(baseAsset);
      quoteSet.add(quoteAsset);
    });

    return {
      baseList: Array.from(baseSet),
      quoteList: Array.from(quoteSet),
    };
  }, [assets]);

  const { pairs, onAssetChange } = useAssetPair(
    defaultBase,
    defaultQuote,
    initialLists.baseList as CoinSymbolType[],
    initialLists.quoteList as CoinSymbolType[],
  );

  const { baseList, quoteList } = useFilteredLists(
    assets,
    pairs.base,
    pairs.quote,
    initialLists.baseList as CoinSymbolType[],
    initialLists.quoteList as CoinSymbolType[],
  );

  const { liveData } = useLastLivePrice({ symbol: concatTicker(pairs.base, pairs.quote) });

  const unitPrice = liveData?.p || '0';

  const { amount, onAmountChange } = useAmountCalculation('1', unitPrice);
  const { updatedTime, updatePrice } = usePriceUpdate();

  const assetPair: AssetPairType = `${pairs.base}_${pairs.quote}`;

  return (
    <Flex width="full">
      <Flex.Item width="full">
        <Panel size="none" my="7xl">
          <Meta
            direction={{ xs: 'col', md: 'row' }}
            justify="start"
            items="start"
            extra={<IconCoin type={pairs.base} size="9xl" />}
            title={
              <Flex gap="xl" items={{ xs: 'center', sm: 'start' }}>
                <Flex.Item>
                  <Text heading size={{ xs: '3xl', sm: '5xl' }} weight="light">
                    <Trans
                      t={t}
                      i18nKey="convert.meta-title"
                      components={{
                        baseSpan: <Span color="orange-500" />,
                        quoteSpan: <Span color="primary-500" />,
                      }}
                      values={{ base: pairs.base, quote: pairs.quote }}
                    />
                  </Text>
                </Flex.Item>
                <Flex.Item>
                  <Text font="primary" size="lg" weight="light" color="neutral-800">
                    {t('convert.description', {
                      base: pairs.base,
                      quote: pairs.quote,
                      baseAmount: amount.base,
                      quoteAmount: amount.quote,
                    })}
                  </Text>
                </Flex.Item>
                <Flex.Item>
                  <Button
                    flexType={{ xs: '1', sm: 'none' }}
                    onClick={() => navigate({ base: pairs.base, quote: pairs.quote })}
                    variant="filled"
                    size="md"
                  >
                    {t('convert.buy', {
                      quote: pairs.quote,
                    })}
                  </Button>
                </Flex.Item>
              </Flex>
            }
          />

          <Flex direction={{ xs: 'col', lg: 'row' }} justify="between" items="center" my="xl" gap="xl">
            <AssetList
              assets={baseList}
              isPending={isPending}
              asset={pairs.base}
              amount={amount.base}
              callback={(value) => onAssetChange(value, 'base')}
              onAmountChange={(value) => onAmountChange(value, 'base')}
            />

            <Hidden show={{ xs: true, sm: true, md: true, lg: false, xl: false }}>
              <Icon type="o:arrow-down" size="md" color="neutral-700" />
            </Hidden>

            <Hidden show={{ xs: false, sm: false, md: false, lg: true, xl: true }}>
              <Icon type="o:arrow-right" size="md" color="neutral-700" />
            </Hidden>

            <AssetList
              assets={quoteList}
              asset={pairs.quote}
              amount={amount.quote}
              callback={(value) => onAssetChange(value, 'quote')}
              onAmountChange={(value) => onAmountChange(value, 'quote')}
              isPending={isPending}
            />
          </Flex>

          <Block row justify="end" gap="sm" items="center" wrap>
            <Text font="primary" size="sm" weight="light" color="neutral-800">
              {t('convert.last-update', {
                updatedTime: updatedTime,
              })}
            </Text>
            <Button
              size="sm"
              onClick={() => updatePrice(onAmountChange, amount.base)}
              endIcon={<Icon color="primary-500" type="o:arrow-path" />}
            >
              <Text color="primary-500" size="sm" weight="semibold">
                {t('convert.update')}
              </Text>
            </Button>
          </Block>
        </Panel>
      </Flex.Item>
      <Flex.Item width="full">
        <Panel border="neutral-400" size="none">
          <AssetPairChart assetPair={assetPair} />
        </Panel>
      </Flex.Item>
      <Flex.Item width="full">
        <RelatedPosts assetPair={assetPair} />
      </Flex.Item>
    </Flex>
  );
};

export default ConvertCard;
