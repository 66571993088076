import { IBlogApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../../client';
import { RecordsPaginationType } from '../../../types';
import {
  BlogAnnouncementType,
  BlogCategoriesType,
  BlogLocaleType,
  BlogParamsType,
  BlogPostType,
  CoinInfoParamsType,
  CoinInfoType,
  FeaturedPostsParamsType,
  FeaturedPostType,
  PostDetailParamsType,
  RelatedPostType,
} from '../model/BlogTypes';

class BlogApi extends HttpClient implements IBlogApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getBlogAnnouncements = async (params: BlogLocaleType) =>
    await this.instance.get<HttpErrorType, BlogAnnouncementType[]>('/announcements', { params });

  public getFeaturedPosts = async (params: BlogLocaleType) =>
    await this.instance.get<HttpErrorType, FeaturedPostType[]>('/blog-posts/featured', { params });

  public getFeaturedPostsV2 = async (params: FeaturedPostsParamsType) =>
    await this.instance.get<HttpErrorType, RecordsPaginationType<FeaturedPostType>>('/blog-posts/featured', { params });

  public getBlogPosts = async (params: BlogParamsType) =>
    await this.instance.get<HttpErrorType, RecordsPaginationType<BlogPostType>>('/blog-posts', { params });

  public getBlogPostsDetail = async ({ slug, locale }: PostDetailParamsType) =>
    await this.instance.get<HttpErrorType, BlogPostType>(`/blog-posts/${slug}`, { params: { locale } });

  public getRelatedPosts = async (params: BlogParamsType) =>
    await this.instance.get<HttpErrorType, RecordsPaginationType<RelatedPostType>>('/blog-posts/related', {
      params,
    });

  public getCategories = async (params: BlogLocaleType) =>
    await this.instance.get<HttpErrorType, BlogCategoriesType[]>('/categories', { params });

  public getBlog = async (id: string) => await this.instance.get<HttpErrorType, any>(`/blog-posts/${id}`);

  public getCoinInfo = async (params: CoinInfoParamsType) =>
    await this.instance.get<HttpErrorType, CoinInfoType>('/coin-info', { params });
}

export default BlogApi;
