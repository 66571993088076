import React, { memo, useState } from 'react';

import { Block, Icon, Label, Panel, Text, Tooltip } from '@bilira-org/design';
import { CryptoNetworkType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { useWalletStore } from '@Components/walletAddress/components/store/walletStore';
import WalletAddressModal from '@Components/walletAddress/WalletAddressModal';
import { TRACKER_EVENTS } from '@Libs/tracker/events';
import { addTrackerCryptoWithdrawal } from '@Libs/tracker/helpers/addTrackerCryptoWithdrawal';

type Props = {
  // activeWallet?: WalletModel;
  // callback: (wallet: WalletModel) => void;
  filter?: CryptoNetworkType;
  disabled?: boolean;
};
const SelectAddressStep = ({ filter, disabled }: Props) => {
  const [selectWalletModal, setSelectWalletModal] = useState(false);
  const { selectedWallet } = useWalletStore();
  const { t } = useTranslation();

  const onClickProp = filter && !disabled ? { onClick: () => setSelectWalletModal(true) } : {};

  return (
    <>
      <Label>{t('crypto.select-withdraw-address')}</Label>
      <Panel mb="md" size="md" ring="neutral-400" color={disabled ? 'neutral-200' : undefined} {...onClickProp}>
        <Block row gap="md" items="center" justify="between">
          {selectedWallet && (
            <Text size="sm" weight="semibold" color="secondary-500">
              {selectedWallet?.address}
            </Text>
          )}
          {!selectedWallet && (
            <Text size="sm" color="neutral-500">
              {t('crypto.select-withdraw-address-placeholder')}
            </Text>
          )}

          <Icon size="lg" color={disabled ? 'neutral-500' : 'neutral-600'} type="o:document-text" />
        </Block>
      </Panel>
      {selectedWallet?.memo && (
        <>
          <Label>
            <Block row gap="xs">
              {t('wallet-address.modal.memo')}
              <Tooltip width="size-60" placement="top" message={t('crypto.withdraw.withdraw-memo-info')}>
                <Icon color="neutral-600" type="s:question-mark-circle" size="sm" />
              </Tooltip>
            </Block>
          </Label>
          <Panel mb="sm" size="md" ring="neutral-400" color={disabled ? 'neutral-200' : undefined}>
            <Block row gap="md" items="center" justify="between">
              <Text size="sm" weight="semibold" color="secondary-500">
                {selectedWallet?.memo}
              </Text>
            </Block>
          </Panel>
        </>
      )}
      <WalletAddressModal
        filter={filter}
        isSelectionMode
        callback={() => {
          addTrackerCryptoWithdrawal(TRACKER_EVENTS.CRYPTO_WITHDRAWAL.SELECT_WALLET, selectedWallet);

          setSelectWalletModal(false);
        }}
        modalActive={selectWalletModal}
        setModalActive={setSelectWalletModal}
      />
    </>
  );
};

export default memo(SelectAddressStep);
