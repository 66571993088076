import React from 'react';

import { Alert, Button, Hidden, Icon, useSafeLocalStorage } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

const RewardInfoAlert = () => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useSafeLocalStorage('REWARD-ALERT', false);

  if (hidden) {
    return null;
  }

  return (
    <Hidden show={{ xs: false, sm: true }}>
      <Alert
        status="yellow"
        mb="lg"
        extra={
          <Button
            testId="hide-crypto-deposit-warning"
            size="md"
            variant="transparent"
            startIcon={<Icon color="neutral-700" type="o:x-mark" />}
            onClick={() => setHidden(true)}
          />
        }
      >
        {t('rewards.reward-info')}
      </Alert>
    </Hidden>
  );
};

export default RewardInfoAlert;
