/**
 * Returns additional headers for HTTP requests.
 * This function provides default extra headers to be included in HTTP requests,
 * with support for overriding specific header value
 *
 * @param {Object} [overrides={}] - Optional object containing header key-value pairs to override the default headers.
 * @returns {Object} An object containing extra headers.
 */
const getExtraHeaders = (overrides: Record<string, string> = {}) => {
  return {
    os: 'web',
    ...overrides,
  };
};

export default getExtraHeaders;
