import React from 'react';

import { Button, Link, Panel, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import Learn from '@Modules/overview/components/learn/components/Learn';

const LearnCard = () => {
  const { t } = useTranslation();

  return (
    <Panel
      testId="learn-panel"
      id="learn-card"
      color="theme-n200d800"
      extra={
        <Link anchor href={t('urls.blogs')} target="_blank">
          <Button variant="link" size="md">
            {t('common.show-all')}
          </Button>
        </Link>
      }
    >
      <Text heading size="xl" weight="semibold">
        {t('overview.learn')}
      </Text>

      <Text py="md">{t('overview.learn-desc')}</Text>
      <Learn />
    </Panel>
  );
};

export default LearnCard;
