import { DefaultAllowanceType } from '../../network/stablecoin/model';
import { formatPrice } from '../currency';

/**
 * Formats the default allowance fields.
 *
 * @param {DefaultAllowanceType} defaultAllowance - The default allowance object.
 * @param {string} locale - The locale to use for formatting.
 * @returns {{ dailyWithdrawLimit: string, monthlyWithdrawLimit: string }} The formatted default allowance fields.
 */
export const formatDefaultAllowanceFields = (defaultAllowance?: DefaultAllowanceType, locale?: string) => {
  if (!defaultAllowance) {
    return {
      dailyWithdrawLimit: '0',
      monthlyWithdrawLimit: '0',
    };
  }
  const formatAllowanceField = (value: string | undefined) => {
    return formatPrice({ value, decimal: 2, locale, defaultValue: '-' }).value;
  };

  const { daily_withdrawal: dailyWithdrawLimit, monthly_withdrawal: monthlyWithdrawLimit } = defaultAllowance;

  return {
    dailyWithdrawLimit: formatAllowanceField(dailyWithdrawLimit),
    monthlyWithdrawLimit: formatAllowanceField(monthlyWithdrawLimit),
  };
};
