import { getSearchParamFromUrl } from '../getSearchParamFromUrl';

const reactQueryHelpers = {
  queryFn: ({ pageParam }: any) => {
    let offset = 0;
    let limit = 10;

    if (pageParam) {
      const searchParams = getSearchParamFromUrl(pageParam as string);
      offset = searchParams('offset') as number;
      limit = searchParams('limit') as number;
    }
    return { offset, limit };
  },
  getNextPageParam: (lastPage: any, allPages: any, lastPageParam: any, allPageParams: any) =>
    (lastPage._metadata.Links?.next as string) ?? undefined,
  getPreviousPageParam: (firstPage: any, allPages: any, firstPageParam: any, allPageParams: any) =>
    firstPage._metadata.Links?.previous ?? undefined,
  getInitialPageParam: ({ limit, offset }: { limit?: number; offset?: number }) =>
    `limit=${limit || 10}&offset=${offset || 0}`,
};

export default reactQueryHelpers;
