import { useMemo } from 'react';

import { ChartPriceDataType } from '@bilira-org/design';
import {
  AssetPairType,
  calculateChangePercentage,
  formatNumberByPrice,
  isEmpty,
  OHLCSType,
  parseAssetPair,
} from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import CryptoQuery from '@Libs/clientInstances/cryptoQuery';
import i18nLanguageToLocale from '@Libs/helpers/i18nLanguageToLocale';
import useUpdatePriceChart from '@Modules/market/detail/components/priceChart/hooks/useUpdatePriceChart';

type Props = {
  priceInfo?: ChartPriceDataType;
  priceData: OHLCSType;
  assetPair: AssetPairType;
};

// TODO: refactor here.
const useAssetInfoHook = ({ priceData, priceInfo, assetPair }: Props) => {
  const [base, quote] = parseAssetPair(assetPair);
  const { i18n } = useTranslation();

  const { priceChartDiffData } = useUpdatePriceChart({ priceData });

  const assetData = CryptoQuery.useGetAssets();
  const assetItem = useMemo(() => assetData.data?.find((d) => d.symbol === base), [assetData.data, base]);

  const calculateDefaultData = () => {
    const { firstData, lastData } = priceChartDiffData;

    if (isEmpty(firstData) || isEmpty(lastData)) {
      return null;
    }

    const locale = i18nLanguageToLocale(i18n.language);

    const change = calculateChangePercentage(firstData.value.toString(), lastData.value.toString());
    return {
      time: dayjs.unix(lastData.time).locale(locale).format('DD MMM YYYY HH:mm'),
      value: lastData.value,
      change: BigNumber(change).dividedBy(100).toString(),
    };
  };

  const calculateCurrentData = () => {
    if (isEmpty(priceInfo)) {
      return null;
    }

    const locale = i18nLanguageToLocale(i18n.language);
    return {
      time: dayjs
        .unix(priceInfo?.time as number)
        .locale(locale)
        .format('DD MMM YYYY HH:mm'),
      value: priceInfo?.value,
      change: BigNumber(priceInfo?.change as string)
        .dividedBy(100)
        .toString(),
    };
  };

  const defaultData = useMemo(
    () => (isEmpty(priceInfo) ? calculateDefaultData() : calculateCurrentData()),
    [priceInfo, priceChartDiffData],
  );

  const price = defaultData?.value?.toString();

  const formattedPrice = formatNumberByPrice({
    type: 'price',
    price: price as string, //"TODO: make price nullable. @sulhadin.
    value: price,
    locale: i18n.language,
    stringifyFormat: '0[.]00a',
  });

  return { formattedPrice, base, quote, defaultData, price, assetItem, isLoading: assetData.isLoading };
};

export default useAssetInfoHook;
