import { AllowancesType, DirectAllowanceType } from '../../network';
import { ForceDecimal, formatPrice, getMax } from '../currency';

type FormattedAllowanceFields = {
  dailyAllowed: string;
  dailyRemaining: string;
  dailyUsed: string;
  monthlyAllowed: string;
  monthlyRemaining: string;
  monthlyUsed: string;
  dailyPercentage: string;
  monthlyPercentage: string;
};

/**
 * Calculates various allowance fields based on the provided allowance data.
 *
 * @param {AllowancesType | undefined} params - The allowance data to be used for calculations.
 * @returns An object containing calculated allowance fields.
 */
const getAllowanceFields = (
  params: AllowancesType | DirectAllowanceType | undefined,
): Record<keyof FormattedAllowanceFields, string> => {
  const dailyAllowed = getMax(params?.daily.allowed || '0', '0');
  const dailyRemaining = getMax(params?.daily.remaining || '0', '0');
  const dailyUsed = dailyAllowed.minus(dailyRemaining);
  const monthlyAllowed = getMax(params?.monthly.allowed || '0', '0');
  const monthlyRemaining = getMax(params?.monthly.remaining || '0', '0');
  const monthlyUsed = monthlyAllowed.minus(monthlyRemaining);

  // (500-250)*100/500
  const dailyPercentage = dailyUsed.multipliedBy(100).dividedBy(dailyAllowed);
  const monthlyPercentage = monthlyUsed.multipliedBy(100).dividedBy(monthlyAllowed);

  return {
    dailyPercentage: dailyPercentage.toString(),
    monthlyPercentage: monthlyPercentage.toString(),
    dailyAllowed: dailyAllowed.toString(),
    dailyRemaining: dailyRemaining.toString(),
    dailyUsed: dailyUsed.toString(),
    monthlyAllowed: monthlyAllowed.toString(),
    monthlyRemaining: monthlyRemaining.toString(),
    monthlyUsed: monthlyUsed.toString(),
  };
};

export const getFormattedAllowanceFields = (
  params: AllowancesType | DirectAllowanceType | undefined,
  locale: string,
): FormattedAllowanceFields => {
  if (!params) {
    return {
      dailyPercentage: '0',
      monthlyPercentage: '0',
      dailyAllowed: '0',
      dailyRemaining: '0',
      dailyUsed: '0',
      monthlyAllowed: '0',
      monthlyRemaining: '0',
      monthlyUsed: '0',
    };
  }

  const allowanceFields = getAllowanceFields(params);
  const formattedFields: FormattedAllowanceFields = {} as FormattedAllowanceFields;
  const fields = Object.keys(allowanceFields) as (keyof FormattedAllowanceFields)[];

  fields.forEach((key) => {
    formattedFields[key] = formatPrice({
      value: allowanceFields[key],
      forceDecimal: ForceDecimal.SHOW_INSIGNIFICANT_DIGITS,
      decimal: 2,
      locale: locale,
    }).values.join('');
  });

  return formattedFields;
};
export default getAllowanceFields;
