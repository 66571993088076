import { useMemo } from 'react';

import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { aggregateTotalBalance } from './utils';
import { HttpClientType, HttpErrorType } from '../../../client';
import { IdType, QueryOptions, RecordsPaginationType, ResultType } from '../../../types';
import reactQueryHelpers from '../../../utils/rq';
import { AssetListType } from '../../price/model';
import { AccountApi, IAccountApi } from '../api';
import { AccountQueryKey, UserQueryKey } from '../constants';
import {
  AccountTransactionParamsType,
  BalanceType,
  TransactionsRecordType,
  WalletBodyModel,
  WalletModel,
  WalletPatchModel,
  WatchlistParamType,
  WatchListSymbolsType,
} from '../model';
import {
  AccountInternalTransferParamsType,
  AccountInternalTransfersParamsType,
  AccountTransactionsParamsType,
  AccountType,
  BalanceParamsType,
  FiatWithdrawalRequestType,
  InternalTransfersType,
  InternalTransferType,
  TransactionHistoryExportAllowanceType,
  TransactionHistoryExportBodyType,
  TransactionsType,
  WatchListType,
} from '../model/Account';
import { BankAccountEntityType, BankAccountPatchType, BankAccountType } from '../model/BankAccount';

class AccountQuery {
  _api: IAccountApi;

  public constructor(params: HttpClientType) {
    this._api = new AccountApi(params);
  }

  public useGetAccounts = (id: string, options?: QueryOptions) =>
    useQuery<AccountType, HttpErrorType>({
      queryKey: [AccountQueryKey.ACCOUNTS, id],
      queryFn: () => this._api.getAccounts(id),
      ...options,
    });

  public useGetBalances = (account: string, params: BalanceParamsType = {}, options?: QueryOptions) =>
    useQuery<BalanceType[], HttpErrorType>({
      queryKey: [AccountQueryKey.ACCOUNT_BALANCES, account, params],
      queryFn: () => this._api.getBalances(account, params).then((result) => result.filter((d) => !d.disabled)),
      enabled: !!account,
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetTotalBalance = (account: string, params: BalanceParamsType = {}, options?: QueryOptions) => {
    const { data, ...rest } = useQuery<BalanceType[], HttpErrorType>({
      queryKey: [AccountQueryKey.ACCOUNT_TOTAL_BALANCE, account, params],
      queryFn: async () => this._api.getBalances(account, params).then((result) => result.filter((d) => !d.disabled)),
      enabled: !!account,
      placeholderData: keepPreviousData,
      ...options,
    });

    return { data: aggregateTotalBalance(data), ...rest };
  };

  public useCryptoWallets = (id: string, options?: QueryOptions) =>
    useQuery<WalletModel[], HttpErrorType>({
      queryKey: [AccountQueryKey.CRYPTO_WALLETS, id],
      queryFn: () => this._api.getCryptoWallets(id),
      ...options,
    });

  public usePostFiatWithdrawal = () => {
    const queryClient = useQueryClient();
    return useMutation<IdType, HttpErrorType, FiatWithdrawalRequestType>({
      mutationFn: this._api.postFiatWithdrawal,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS] }).then();
        queryClient.invalidateQueries({ queryKey: [UserQueryKey.NOTIFICATIONS] }).then();
      },
    });
  };

  public useTransactions = (params: AccountTransactionsParamsType, options?: QueryOptions) =>
    useQuery<TransactionsType>({
      queryKey: [AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS, params],
      queryFn: () => this._api.getTransactions(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useTransaction = (params: AccountTransactionParamsType, options?: QueryOptions) =>
    useQuery<TransactionsRecordType>({
      queryKey: [AccountQueryKey.ACCOUNT_ALL_TRANSACTION, params],
      queryFn: () => this._api.getTransaction(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetInternalTransfer = (params: AccountInternalTransferParamsType, options?: QueryOptions) =>
    useQuery<InternalTransferType>({
      queryKey: [AccountQueryKey.ACCOUNT_INTERNAL_TRANSFER, params],
      queryFn: () => this._api.getInternalTransfer(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetInternalTransfers = (params: AccountInternalTransfersParamsType, options?: QueryOptions) =>
    useQuery<InternalTransfersType>({
      queryKey: [AccountQueryKey.ACCOUNT_INTERNAL_TRANSFERS, params],
      queryFn: () => this._api.getInternalTransfers(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public usePostTransactionExportRequest = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<null, HttpErrorType, { accountId: string; body: TransactionHistoryExportBodyType }>({
      mutationFn: ({ accountId, body }) => this._api.postTransactionExportRequest(accountId, body),
      onSuccess: () => queryClient.resetQueries({ queryKey: [AccountQueryKey.TRANSACTION_EXPORT_ALLOWANCE] }),
      ...options,
    });
  };

  public useGetTransactionExportAllowance = (accountId: string, options?: QueryOptions) => {
    return useQuery<TransactionHistoryExportAllowanceType, HttpErrorType>({
      queryKey: [AccountQueryKey.TRANSACTION_EXPORT_ALLOWANCE, accountId],
      queryFn: () => this._api.getTransactionExportAllowance(accountId),
      ...options,
    });
  };

  public useGetCryptoWallets = (accountId: string, options?: QueryOptions) => {
    return useQuery<WalletModel[], HttpErrorType>({
      queryKey: [AccountQueryKey.CRYPTO_WALLETS, accountId],
      queryFn: () => this._api.getCryptoWallets(accountId),
      placeholderData: keepPreviousData,
      ...options,
    });
  };

  public usePostCryptoWallet = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation<any, HttpErrorType, { body: Partial<WalletBodyModel>; id: string }>({
      mutationFn: this._api.postCryptoWallet,
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [AccountQueryKey.CRYPTO_WALLETS],
        }),
      ...options,
    });
  };

  public usePatchCryptoWallet = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation<any, HttpErrorType, { id: string; body: WalletPatchModel }>({
      mutationFn: this._api.patchCryptoWallet,
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [AccountQueryKey.CRYPTO_WALLETS],
        }),
      ...options,
    });
  };

  public useDeleteCryptoWallet = (options?: QueryOptions) => {
    return useMutation({ mutationFn: this._api.deleteCryptoWallet, ...options });
  };

  // public usePatchDefaultCryptoWallet = (options?: QueryOptions) => {
  //   const queryClient = useQueryClient();
  //   return useMutation({
  //     mutationFn: this._api.patchDefaultCryptoWallet,
  //     onSuccess: () =>
  //       queryClient.invalidateQueries({
  //         queryKey: [AccountQueryKey.CRYPTO_WALLETS],
  //       }),
  //     ...options,
  //   });
  // };

  //BANK ACCOUNT
  public usePatchBankAccount = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<ResultType, HttpErrorType, { id: string; body: BankAccountPatchType }>({
      mutationFn: this._api.patchBankAccount,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: [AccountQueryKey.BANK_ACCOUNTS],
        });
        queryClient.resetQueries({
          queryKey: [AccountQueryKey.ACCOUNT_ALL_TRANSACTION],
        });
      },
      ...options,
    });
  };
  public usePostBankAccount = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<IdType, HttpErrorType, { id: string; body: BankAccountEntityType }>({
      mutationFn: this._api.postBankAccount,
      onSuccess: () =>
        queryClient.resetQueries({
          queryKey: [AccountQueryKey.BANK_ACCOUNTS],
        }),
      ...options,
    });
  };

  public useGetBankAccounts = (id: string, isDefault = false, options?: QueryOptions) =>
    useQuery<BankAccountType[]>({
      queryKey: [AccountQueryKey.BANK_ACCOUNTS, id, isDefault],
      queryFn: () => this._api.getBankAccounts(id, isDefault),
      ...options,
    });

  public useDeleteBankAccount = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: this._api.deleteBankAccount,
      onSuccess: () =>
        queryClient.resetQueries({
          queryKey: [AccountQueryKey.BANK_ACCOUNTS],
        }),
      ...options,
    });
  };
  public usePatchDefaultBankAccount = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: this._api.patchDefaultBankAccount,
      onSuccess: () =>
        queryClient.resetQueries({
          queryKey: [AccountQueryKey.BANK_ACCOUNTS],
        }),
      ...options,
    });
  };

  public useGetWatchList = (category: string, options?: QueryOptions) =>
    useQuery<WatchListSymbolsType[]>({
      queryKey: [AccountQueryKey.WATCH_LIST, category],
      queryFn: () => this._api.getWatchList(category),
      staleTime: Infinity,
      ...options,
    });

  public useGetWatchListData = (params: WatchlistParamType, options?: QueryOptions) => {
    const rest = useInfiniteQuery<RecordsPaginationType<WatchListType>, HttpErrorType>({
      queryKey: [AccountQueryKey.WATCH_LIST_DATA, params],
      queryFn: ({ pageParam }) => {
        const { offset, limit } = reactQueryHelpers.queryFn({ pageParam });
        return this._api.getWatchListData({ ...params, offset, limit });
      },
      placeholderData: keepPreviousData,
      getNextPageParam: reactQueryHelpers.getNextPageParam,
      getPreviousPageParam: reactQueryHelpers.getPreviousPageParam,
      initialPageParam: reactQueryHelpers.getInitialPageParam(params),
      ...options,
    });

    const article = useMemo(
      () => rest?.data?.pages?.reduce((acc, pages) => [...acc, ...pages.records], [] as AssetListType[]),
      [rest?.data?.pages],
    );

    return {
      pages: article,
      pageParams: rest.data?.pageParams,
      ...rest,
    };
  };

  public usePutWatchList = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: this._api.putWatchList,
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [AccountQueryKey.WATCH_LIST],
        });
        queryClient.refetchQueries({
          queryKey: [AccountQueryKey.WATCH_LIST_DATA],
        });
      },
      ...options,
    });
  };

  public useDeleteWatchList = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: this._api.deleteWatchList,
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [AccountQueryKey.WATCH_LIST],
        });
        queryClient.refetchQueries({
          queryKey: [AccountQueryKey.WATCH_LIST_DATA],
        });
      },
      ...options,
    });
  };
}

export default AccountQuery;
