import { useState } from 'react';

import { SystemBankAccountModel } from '@bilira-org/react-utils';

export const useActiveSystemBankPanel = () => {
  const [modalActive, setModalActive] = useState(false);
  const [selectedSystemBank, setSelectedSystemBank] = useState<SystemBankAccountModel | null>(null);

  return {
    modalActive,

    setModalActive,

    selectedSystemBank,
    setSelectedSystemBank,
  };
};
