import React from 'react';

import { Flex, LayoutV2, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import HeadTags from '@/components/common/HeadTags';
import BuySellCard from '@Components/trade/buySell/BuySellCard';

const { Content, InnerContent, Hero } = LayoutV2;

function Swap() {
  const { t } = useTranslation();

  return (
    <>
      <HeadTags title={t('market.swap.do-swap')} titleSuffix="subpage" />
      <Hero>
        <Text heading size="xl" weight="bold" my="md">
          {t('market.swap.do-swap')}
        </Text>
      </Hero>
      <Content>
        <InnerContent>
          <Flex width={{ xs: 'full', sm: '450' }} justify="center" mx="auto">
            <Flex.Item grow="1">
              <BuySellCard hiddenProps={{ show: { xs: true, sm: true } }} />
            </Flex.Item>
          </Flex>
        </InnerContent>
      </Content>
    </>
  );
}

export default Swap;
