import { UserQuery } from '@bilira-org/react-utils';

import { env } from '@Libs/constants/env';

import getExtraHeaders from '../helpers/getExtraHeaders';
import getI18nLanguage from '../helpers/getI18nLanguage';

const client = new UserQuery({
  baseUrl: env.STABLECOIN_API_BASE_URL,
  params: { withCredentials: true },
  getLocale: getI18nLanguage,
  // Overriding default headers to disable caching for authentication requests.
  // Caching these requests causes issues when a user logs out and navigates back using the browser.
  // The browser reads the cached response, leading to a UI inconsistency where the user appears logged in despite being logged out.
  getExtraHeaders: () => getExtraHeaders({ 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' }),
});

Object.freeze(client);

export default client;
