import { Breakpoints, TourStepType } from '@bilira-org/design';

const onboardingSteps: (TourStepType & {
  url: string;
  show?: Breakpoints[];
  title: string;
  content: string;
})[] = [
  {
    target: 'body',
    title: 'tour.onboarding.welcome.title',
    content: 'tour.onboarding.welcome.description',
    disableBeacon: true,
    placement: 'center',
    url: '/',
  },
  {
    target: '#deposit-buttons',
    title: 'tour.onboarding.fiat-crypto-deposit.title',
    content: 'tour.onboarding.fiat-crypto-deposit.description',
    placement: 'auto',
    url: '/',
  },
  {
    target: '#navigation-swap',
    title: 'tour.onboarding.buy-sell.title',
    content: 'tour.onboarding.buy-sell.description',
    placement: 'auto',
    url: '/',
    show: ['lg', 'xl'],
  },
  {
    target: '#navbar',
    title: 'tour.onboarding.navigation.title',
    content: 'tour.onboarding.navigation.description',
    disableBeacon: true,
    placement: 'right',
    url: '/',
    show: ['xl'],
  },
  {
    target: '#hamburger-menu-mobile',
    title: 'tour.onboarding.navigation.title',
    content: 'tour.onboarding.navigation.description',
    disableBeacon: true,
    placement: 'right',
    url: '/',
    show: ['xs', 'sm', 'md', 'lg'],
  },
  {
    target: '#wallet-overview',
    title: 'tour.onboarding.total-asset-value.title',
    content: 'tour.onboarding.total-asset-value.description',
    placement: 'auto',
    url: '/',
  },
  {
    target: '#crypto-list-cards',
    title: 'tour.onboarding.crypto-asset-lists.title',
    content: 'tour.onboarding.crypto-asset-lists.description',
    placement: 'bottom',
    url: '/',
  },
  {
    target: '#announcement',
    title: 'tour.onboarding.announcements.title',
    content: 'tour.onboarding.announcements.description',
    placement: 'auto',
    url: '/',
  },
  {
    target: '#learn-card',
    title: 'tour.onboarding.academy.title',
    content: 'tour.onboarding.academy.description',
    placement: 'auto',
    url: '/',
  },
];

const tours = {
  onboarding: onboardingSteps,
} as const;

export default tours;
