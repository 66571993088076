import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../../client';
import { QueryOptions } from '../../../types';
import { IRewardsApi, RewardsApi } from '../api';
import { RewardsQueryKey } from '../constants';
import { UserPointType } from '../model';
import {
  DailyLoginType,
  PrerequisitesType,
  SeasonsType,
  TaskHistoryParamsType,
  UserParticipationsType,
  UserPrerequisitesType,
  UserTaskHistoryType,
} from '../model/Rewards';

class RewardsQuery {
  _api: IRewardsApi;

  public constructor(params: HttpClientType) {
    this._api = new RewardsApi(params);
  }

  // public useGetSeasonFeaturedTasks = (options?: QueryOptions) =>
  //   useQuery<RewardsTasksType[], HttpErrorType>({
  //     queryKey: [RewardsQueryKey.SEASON_FEATURED_TASKS_KEY],
  //     queryFn: () => this._api.getSeasonFeaturedTasks(),
  //     ...options,
  //   });

  public useGetSeasons = (options?: QueryOptions) =>
    useQuery<SeasonsType, HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_SEASONS_KEY],
      queryFn: () => this._api.getSeasons(),
      ...options,
    });

  public useGetUserParticipation = (options?: QueryOptions) =>
    useQuery<UserParticipationsType, HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_USER_PARTICIPATION_KEY],
      queryFn: () => this._api.getUserParticipation(),
      ...options,
    });

  public usePatchUserParticipation = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, HttpErrorType>({
      mutationFn: () => this._api.patchUserParticipation(),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [RewardsQueryKey.REWARDS_USER_PARTICIPATION_PREREQUISITES_KEY] });
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: [RewardsQueryKey.REWARDS_USER_PARTICIPATION_PREREQUISITES_KEY] });
      },
      ...options,
    });
  };

  public useGetUserTaskHistory = (params: TaskHistoryParamsType = {}, options?: QueryOptions) =>
    useQuery<UserTaskHistoryType[], HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_USER_TASK_HISTORY_KEY, params],
      queryFn: () => this._api.getUserTaskHistory(params),
      ...options,
    });

  public useGetPoints = (options?: QueryOptions) =>
    useQuery<UserPointType, HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_USER_POINT_KEY],
      queryFn: () => this._api.getUserPoints(),
      ...options,
    });

  // public useGetLeaderboard = (seasonId: string, options?: QueryOptions) =>
  //   useQuery<LeaderBoardType[], HttpErrorType>({
  //     queryKey: [RewardsQueryKey.REWARDS_LEADER_BOARD_KEY, seasonId],
  //     queryFn: () => this._api.getLeaderboard(seasonId),
  //     enabled: !!seasonId,
  //     ...options,
  //   });

  public useGetDailyLogin = (options?: QueryOptions) =>
    useQuery<DailyLoginType, HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_DAILY_LOGIN_KEY],
      queryFn: () => this._api.getDailyLogin(),
      ...options,
    });

  public usePostDailyLogin = (options?: QueryOptions) =>
    useMutation<undefined, HttpErrorType>({
      mutationFn: () => this._api.postDailyLogin(),
      ...options,
    });

  public useGetUserParticipationPrerequisites = (options?: QueryOptions) =>
    useQuery<UserPrerequisitesType, HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_USER_PARTICIPATION_PREREQUISITES_KEY],
      queryFn: () => this._api.getUserParticipationPrerequisites(),
      ...options,
    });

  public useGetParticipationPrerequisites = (options?: QueryOptions) =>
    useQuery<PrerequisitesType, HttpErrorType>({
      queryKey: [RewardsQueryKey.REWARDS_PARTICIPATION_PREREQUISITES_KEY],
      queryFn: () => this._api.getParticipationPrerequisites(),
      ...options,
    });
}

export default RewardsQuery;
