import { useMemo } from 'react';

import { CoinSymbolType, SwapPairType } from '@bilira-org/react-utils';

const useFilteredLists = (
  assets: SwapPairType[] | undefined,
  base: CoinSymbolType | undefined,
  quote: CoinSymbolType | undefined,
  initialBaseList: CoinSymbolType[],
  initialQuoteList: CoinSymbolType[],
) => {
  return useMemo(() => {
    if (!assets) return { baseList: [] as CoinSymbolType[], quoteList: [] as CoinSymbolType[] };

    const filteredQuoteSet = new Set<CoinSymbolType>(
      base ? assets.filter((item) => item.base_asset === base).map((item) => item.quote_asset) : initialQuoteList,
    );

    const filteredBaseSet = new Set<CoinSymbolType>(
      quote ? assets.filter((item) => item.quote_asset === quote).map((item) => item.base_asset) : initialBaseList,
    );

    return {
      baseList: Array.from(filteredBaseSet),
      quoteList: Array.from(filteredQuoteSet),
    };
  }, [assets, base, quote, initialBaseList, initialQuoteList]);
};

export default useFilteredLists;
