import { TimeStamp } from '@bilira-org/design';
import { OHLCSDataType } from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';

export type PriceChartDataType = {
  time: TimeStamp;
  value: number;
};

const getPriceChartData = (
  data?: OHLCSDataType,
): { atlIndex: number; priceData: PriceChartDataType[]; athIndex: number } => {
  if (!data) {
    return { priceData: [], athIndex: -1, atlIndex: -1 };
  }

  const allTimeValuesIndex = { athIndex: 0, atlIndex: 0 };

  const result: PriceChartDataType[] = [];

  for (let i = 0; i < data.closes.length; i++) {
    if (BigNumber(data.closes[i]).toNumber() === 0) {
      continue;
    }

    if (BigNumber(data.closes[i]).isGreaterThan(data.closes[allTimeValuesIndex.athIndex])) {
      allTimeValuesIndex.athIndex = i;
    }

    if (BigNumber(data.closes[i]).isLessThan(data.closes[allTimeValuesIndex.atlIndex])) {
      allTimeValuesIndex.atlIndex = i;
    }

    result.push({
      time: data.timestamps[i] as TimeStamp,
      value: BigNumber(data.closes[i]).toNumber(),
    });
  }

  return { priceData: result, ...allTimeValuesIndex };
};

export default getPriceChartData;
