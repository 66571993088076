import { extractBankCode } from '../extractBankCode';

const EMONEY_BANK_CODES = [
  '00829', // Papara
  '00869', // Paycell
];

const EMONEY_IBANS = [
  // Ininal
  'TR620006701000000077521091',
  'TR880011100000000098726591',
  'TR360004600789888000069361',
  'TR910013400001632678600001',
  'TR380006400000113930036581',
  'TR560001002247786851675002',
  'TR820001500158007312024627',
  'TR830006200011900006294029',
  'TR260005903720130372006707',
];

export const isIbanFromBlacklistedBank = (iban: string) => {
  return EMONEY_BANK_CODES.includes(extractBankCode(iban)) || EMONEY_IBANS.includes(iban);
};
