import React, { useEffect, useState } from 'react';

import { Block, Skeleton, Text, useDarkMode } from '@bilira-org/design';
import Radio from '@bilira-org/design/src/components/radio/radio';
import type { KeyValuePairType } from '@bilira-org/react-utils';
import { IdType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

type DataType = {
  id: string;
  key: string;
  label: string;
};

type Props = {
  callback: (data: KeyValuePairType) => Promise<IdType>;
  value?: string;
  loading?: boolean;
};

function Theme({ value, callback, loading }: Props) {
  const { t } = useTranslation();
  const [, setIsDark] = useDarkMode();
  const [theme, setTheme] = useState<DataType>();
  const [pendingTheme, setPendingTheme] = useState<DataType>();

  const data = [
    { id: 'light', label: t('account.preferences.theme-light') },
    { id: 'system', label: t('account.preferences.theme-system') },
    { id: 'dark', label: t('account.preferences.theme-dark') },
  ] as DataType[];

  useEffect(() => {
    const val = data.find((d) => d.id === value);
    setTheme(val);
  }, [value]);

  const handleThemeChange = async (data: DataType) => {
    try {
      setPendingTheme(data);
      await callback({ key: 'theme', value: data.id });

      const isDarkTheme = data.id === 'dark';
      setIsDark(data.id === 'system' ? null : isDarkTheme);
      setTheme(data);
    } finally {
      setPendingTheme(undefined);
    }
  };

  if (loading) {
    return (
      <Block row gap="md" justify="between">
        <Text>{t('account.preferences.theme-title')}</Text>

        <Block row gap="6xl">
          <Skeleton height="size-6" width="size-12" />
          <Skeleton height="size-6" width="size-12" />
          <Skeleton height="size-6" width="size-12" />
        </Block>
      </Block>
    );
  }

  return (
    <Block row gap="md" justify="between">
      <Text>{t('account.preferences.theme-title')}</Text>

      <Radio selected={theme} setSelected={handleThemeChange} dataSource={data} loading={pendingTheme} />
    </Block>
  );
}

export default Theme;
