import { ColumnDef, Skeleton } from '@bilira-org/design';
import {
  AllTransactionsRecordType,
  CryptoTransactionRecordType,
  FiatTransactionRecordType,
  getIsInternalTransferType,
  InternalTransferType,
  OTCTransactionType,
  SwapTransactionType,
  TransactionTypes,
} from '@bilira-org/react-utils';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import FormattedNumberByPrice from '@/components/common/FormattedNumberByPrice';
import { TRANSACTION_STATUS } from '@Libs/helpers/getStateConfig';

import CellText from '../components/CellText';
import HeaderText from '../components/HeaderText';
import TransactionAmount from '../components/TransactionAmount';
import TransactionId from '../components/TransactionId';
import TransactionPair from '../components/TransactionPair';

type ColumnMap = {
  fiat: ColumnDef<FiatTransactionRecordType, any>[];
  crypto: ColumnDef<CryptoTransactionRecordType, any>[];
  otc: ColumnDef<OTCTransactionType, any>[];
  swap: ColumnDef<SwapTransactionType, any>[];
  other: ColumnDef<InternalTransferType, any>[];
};

/**
 * Helper function to get the table columns for the specified transaction type.
 * @param type The type of transaction.
 * @param t The translation function.
 * @param locale The locale.
 * @returns The table columns for the specified transaction type.
 */

function getColumns(type: TransactionTypes, t: TFunction, locale: string): ColumnDef<AllTransactionsRecordType>[] {
  const columnMap: ColumnMap = {
    fiat: [
      {
        accessorKey: 'created_at',
        header: () => <HeaderText>{t('transactions.columns.date')}</HeaderText>,
        cell: (cell) => <CellText>{dayjs(cell.getValue(), { locale }).format('DD/MM/YYYY, HH:mm:ss')}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'direction',
        header: () => <HeaderText>{t('transactions.columns.transfer')}</HeaderText>,
        cell: (cell) => <CellText>{t(`transactions.${cell.getValue()}`)}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'direction',
        id: 'currency',
        header: () => <HeaderText>{t('transactions.columns.currency')}</HeaderText>,
        cell: () => <CellText>TRY</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'amount',
        header: () => <HeaderText>{t('transactions.columns.amount')}</HeaderText>,
        cell: (cell) => (
          <TransactionAmount
            amount={cell.row.original.amount}
            withdrawalFee={cell.row.original['withdrawal_fee' as keyof typeof cell.row.original]?.toString() || null}
            price="1"
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'status',
        header: () => <HeaderText>{t('transactions.columns.status')}</HeaderText>,
        cell: (cell) => (
          <CellText color={TRANSACTION_STATUS[cell.row.original.status].text}>
            {t(`transactions.statuses.${cell.row.original.status}`)}
          </CellText>
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
    ],
    crypto: [
      {
        accessorKey: 'created_at',
        header: () => <HeaderText>{t('transactions.columns.date')}</HeaderText>,
        cell: (cell) => <CellText>{dayjs(cell.getValue(), { locale }).format('DD/MM/YYYY, HH:mm:ss')}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'direction',
        header: () => <HeaderText>TRANSFER</HeaderText>,
        cell: (cell) => <CellText>{t(`transactions.${cell.getValue()}`)}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'asset',
        header: () => <HeaderText>{t('transactions.columns.crypto')}</HeaderText>,
        cell: (cell) => <CellText>{cell.getValue()}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'amount',
        header: () => <HeaderText>{t('transactions.columns.amount')}</HeaderText>,
        cell: (cell) => (
          <TransactionAmount
            amount={cell.row.original.amount}
            withdrawalFee={cell.row.original['withdrawal_fee' as keyof typeof cell.row.original]?.toString() || null}
            base={cell.row.original.asset}
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'id',
        header: () => <HeaderText>TxID</HeaderText>,
        cell: (cell) => <TransactionId txId={cell.getValue()} txUrl={cell.row.original.transaction_url} />,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'status',
        header: () => <HeaderText>{t('transactions.columns.status')}</HeaderText>,
        cell: (cell) => (
          <CellText color={TRANSACTION_STATUS[cell.row.original.status].text}>
            {t(`transactions.statuses.${cell.row.original.status}`)}
          </CellText>
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
    ],
    swap: [
      {
        accessorKey: 'created_at',
        header: () => <HeaderText>{t('transactions.columns.date')}</HeaderText>,
        cell: (cell) => <CellText>{dayjs(cell.getValue(), { locale }).format('DD/MM/YYYY, HH:mm:ss')}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'pair',
        header: () => <HeaderText>{t('transactions.columns.pair')}</HeaderText>,
        cell: (cell) => <TransactionPair pair={cell.getValue()} />,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'direction',
        header: () => <HeaderText>{t('transactions.columns.transaction')}</HeaderText>,
        cell: (cell) => <CellText>{t(`transactions.${cell.getValue()}`)}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'offer_price',
        header: () => <HeaderText>{t('transactions.columns.price')}</HeaderText>,
        cell: (cell) => {
          const price =
            cell.row.original.side === 'buy' ? cell.getValue() : BigNumber(1).div(cell.getValue()).toString();

          return <FormattedNumberByPrice size="sm" color="neutral-900" type="price" price={price} value={price} />;
        },
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'executed_amount',
        header: () => <HeaderText>{t('transactions.columns.executed')}</HeaderText>,
        cell: (cell) => {
          const price =
            cell.row.original.side === 'buy'
              ? cell.row.original.offer_price
              : BigNumber(1).div(cell.row.original.offer_price).toString();

          return (
            <FormattedNumberByPrice
              size="sm"
              color="neutral-900"
              type="amount"
              price={price}
              value={cell.getValue()}
              suffix={cell.row.original.to_asset}
            />
          );
        },
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'commission',
        header: () => <HeaderText>{t('transactions.columns.commission')}</HeaderText>,
        cell: (cell) => (
          <FormattedNumberByPrice
            size="sm"
            color="neutral-900"
            value={cell.getValue()}
            suffix={cell.row.original.commission_asset}
            base={cell.row.original.commission_asset}
            type="amount"
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
    ],
    otc: [
      {
        accessorKey: 'created_at',
        header: () => <HeaderText>{t('transactions.columns.date')}</HeaderText>,
        cell: (cell) => <CellText>{dayjs(cell.getValue(), { locale }).format('DD/MM/YYYY, HH:mm:ss')}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'pair',
        header: () => <HeaderText>{t('transactions.columns.pair')}</HeaderText>,
        cell: (cell) => <TransactionPair pair={cell.getValue()} />,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'direction',
        header: () => <HeaderText>{t('transactions.columns.transaction')}</HeaderText>,
        cell: (cell) => <CellText>{t(`transactions.${cell.getValue()}`)}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'pair_price',
        header: () => <HeaderText>{t('transactions.columns.price')}</HeaderText>,
        cell: (cell) => (
          <FormattedNumberByPrice
            size="sm"
            color="neutral-900"
            type="price"
            price={cell.getValue()}
            value={cell.getValue()}
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'executed_amount',
        header: () => <HeaderText>{t('transactions.columns.executed')}</HeaderText>,
        cell: (cell) => (
          <FormattedNumberByPrice
            size="sm"
            color="neutral-900"
            type={cell.row.original.side === 'buy' ? 'amount' : 'price'}
            price={cell.row.original.pair_price}
            value={cell.getValue()}
            suffix={cell.row.original.to_asset}
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'commission_amount',
        header: () => <HeaderText>{t('transactions.columns.commission')}</HeaderText>,
        cell: (cell) => (
          <FormattedNumberByPrice
            size="sm"
            color="neutral-900"
            value={cell.getValue()}
            suffix={cell.row.original.commission_asset}
            type="amount"
            {...(cell.row.original.commission_asset ? { base: cell.row.original.commission_asset } : { price: '1' })}
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
    ],
    other: [
      {
        accessorKey: 'created_at',
        header: () => <HeaderText>{t('transactions.columns.date')}</HeaderText>,
        cell: (cell) => <CellText>{dayjs(cell.getValue(), { locale }).format('DD/MM/YYYY, HH:mm:ss')}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'asset',
        header: () => <HeaderText>{t('transactions.columns.crypto')}</HeaderText>,
        cell: (cell) => <CellText>{cell.getValue()}</CellText>,
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'amount',
        header: () => <HeaderText>{t('transactions.columns.amount')}</HeaderText>,
        cell: (cell) => (
          <FormattedNumberByPrice
            size="sm"
            color="neutral-900"
            value={cell.row.original.amount}
            type="amount"
            base={cell.row.original.asset}
          />
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
      {
        accessorKey: 'status',
        header: () => <HeaderText>{t('transactions.columns.status')}</HeaderText>,
        cell: () => (
          <CellText color={TRANSACTION_STATUS['completed'].text}>{t(`transactions.statuses.completed`)}</CellText>
        ),
        skeleton: <Skeleton height="size-6" width="size-40" />,
      },
    ],
  };

  const transformedType = getIsInternalTransferType(type) ? 'other' : (type as keyof ColumnMap);

  return columnMap[transformedType] as ColumnDef<AllTransactionsRecordType>[];
}

export default getColumns;
