import { useMemo } from 'react';

import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../../client';
import { QueryOptions } from '../../../types';
import reactQueryHelpers from '../../../utils/rq';
import { AlertApi, IAlertApi } from '../api';
import { AlertQueryKey } from '../constants';
import { AlertsType, AlertType, GetAlertsParams } from '../model';

class AlertQuery {
  _api: IAlertApi;

  public constructor(params: HttpClientType) {
    this._api = new AlertApi(params);
  }

  public useGetAlerts = (params: GetAlertsParams, options?: QueryOptions) => {
    const result = useInfiniteQuery<AlertsType, HttpErrorType>({
      queryKey: [AlertQueryKey.ALERTS, params],
      queryFn: ({ pageParam }) => {
        const { offset, limit } = reactQueryHelpers.queryFn({ pageParam });

        return this._api.getAlerts({ ...params, offset, limit });
      },
      placeholderData: keepPreviousData,
      getNextPageParam: reactQueryHelpers.getNextPageParam,
      getPreviousPageParam: reactQueryHelpers.getPreviousPageParam,
      initialPageParam: reactQueryHelpers.getInitialPageParam(params),
      ...options,
    });

    const pages = useMemo(
      () =>
        result?.data?.pages?.reduce((acc, pages) => [...acc, ...pages.records], [] as AlertType[]) ||
        ([] as AlertType[]),
      [result?.data?.pages],
    );

    return {
      pages,
      pageParams: result.data?.pageParams,
      ...result,
    };
  };

  public useGetAlert = (id: string, options?: QueryOptions) =>
    useQuery<AlertType, HttpErrorType>({
      queryKey: [AlertQueryKey.ALERT, id],
      queryFn: () => this._api.getAlert(id),
      ...options,
    });

  public usePostAlert = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: this._api.postAlert,
      onSuccess: () => {
        queryClient.resetQueries({
          queryKey: [AlertQueryKey.ALERTS],
        });
      },
      ...options,
    });
  };

  public useDeleteAlert = (options?: QueryOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: this._api.deleteAlert,
      onSuccess: (data, id) => {
        queryClient.resetQueries({
          queryKey: [AlertQueryKey.ALERTS],
        });
        queryClient.resetQueries({
          queryKey: [AlertQueryKey.ALERT, id],
        });
      },
      ...options,
    });
  };
}

export default AlertQuery;
