import { HttpClient, HttpClientType, HttpErrorType } from '../../../client';
import { RecordsPaginationType } from '../../../types';
import { IPriceApi } from '../api/interface';
import {
  AllPricesType,
  AssetListParamType,
  AssetListType,
  LimitedOHLCSListParamType,
  LimitedOHLCSParamType,
  OHLCSListType,
  OHLCSParamType,
  OHLCSType,
  PriceAssetTagType,
  SymbolStatisticsDataType,
} from '../model/PriceTypes';

class PriceApi extends HttpClient implements IPriceApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getOHLCS = (params: OHLCSParamType) =>
    this.instance.get<HttpErrorType, OHLCSType>(`/get-ohlcs`, {
      params: params,
    });

  public getLimitedOHLCS = (params: LimitedOHLCSParamType) =>
    this.instance.get<HttpErrorType, OHLCSType>(`/get-ohlcs-count`, {
      params: params,
    });

  public getLimitedOHLCSList = (params: LimitedOHLCSListParamType) =>
    this.instance.get<HttpErrorType, OHLCSListType>(`/get-ohlcs-counts`, {
      params: params,
    });

  public getAllPrices = () => this.instance.get<HttpErrorType, AllPricesType[]>(`/get-all-prices`);

  public getSymbolStatistics = (symbol: string) =>
    this.instance.get<HttpErrorType, SymbolStatisticsDataType>(`/get-symbol-statistics`, { params: { symbol } });

  public getAssetList = (params: AssetListParamType) =>
    this.instance.get<HttpErrorType, RecordsPaginationType<AssetListType>>(`/get-symbol-info-extendeds`, {
      params,
    });

  public getAssetTags = () => this.instance.get<HttpErrorType, PriceAssetTagType[]>('/get-asset-tags');
}

export default PriceApi;
