import { useCallback, useEffect, useState } from 'react';

import { BuySellCoinType } from '@Components/trade/types';
import calculateConvertPrice from '@Modules/convert/helpers/calculatePrice';

const useAmountCalculation = (initialBaseAmount: string, unitPrice: string) => {
  const [amount, setAmount] = useState<Record<BuySellCoinType, string>>({
    base: initialBaseAmount,
    quote: calculateConvertPrice(initialBaseAmount, unitPrice, 'quote') || '0',
  });

  useEffect(() => {
    const initialQuoteAmount = calculateConvertPrice(initialBaseAmount, unitPrice, 'quote') || '0';
    setAmount((prev) => ({ ...prev, quote: initialQuoteAmount }));
  }, [initialBaseAmount, unitPrice]);

  const onAmountChange = useCallback(
    (value: string, direction: BuySellCoinType) => {
      const otherType = direction === 'quote' ? 'base' : 'quote';
      const calculatedAmount = calculateConvertPrice(value, unitPrice, otherType);

      setAmount((prev) => ({ ...prev, [direction]: value, [otherType]: calculatedAmount }));
    },
    [unitPrice],
  );

  return { amount, onAmountChange };
};

export default useAmountCalculation;
