import React, { memo } from 'react';

import { Button, Flex, Icon, Input, Text } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import useMyAssetTableHook from '@Modules/assets/components/components/hooks/useMyAssetTableHook';
import MyAssetTable from '@Modules/assets/components/components/MyAssetTable';

// import ConversionModal from '../modal/ConversionModal';

const MyAssetContainer = () => {
  const { t } = useTranslation();

  const {
    data,
    searchText,
    onSearch,
    isPending,
    isEnabled,
    setIsEnabled,
    subscriptionList,
    // isConversionModalOpen,
    // setIsConversionModalOpen,
  } = useMyAssetTableHook();

  return (
    <>
      <MarketPriceSubscription channel="my-assets" baseQuoteList={subscriptionList} />
      <Flex p="2xl" id="assets">
        <Flex direction="row" justify="between" items="center" mb="md" shrink="1" gap="md">
          <Input.Search
            variant="bordered"
            customWidth={{ xs: '140', sm: '208' }}
            iconStart={<Icon size="md" type="o:magnifying-glass" />}
            placeholder={t('common.search')}
            value={searchText}
            onChange={(val) => onSearch(val.target.value)}
          />
          <Flex direction="row" shrink="1" gap="md" style={{ minWidth: '0' }}>
            <Button
              variant="outline"
              size="sm"
              startIcon={<Icon type={isEnabled ? 'o:circle-stack-slash' : 'o:circle-stack'} />}
              onClick={() => setIsEnabled(!isEnabled)}
              stretch
            >
              <Text truncate wordBreak="all">
                {isEnabled ? t('assets.show-low-balances') : t('assets.hide-low-balances')}
              </Text>
            </Button>
            {/* <Button
              variant="outline"
              size="sm"
              startIcon={<Icon type="o:arrow-path" />}
              onClick={() => setIsConversionModalOpen(true)}
            >
              {t('assets.convert-low-balances')}
            </Button> */}
          </Flex>
        </Flex>

        <MyAssetTable data={data} loading={isPending} />
      </Flex>
      {/*<ConversionModal data={data} open={isConversionModalOpen} onClose={() => setIsConversionModalOpen(false)} />*/}
    </>
  );
};

export default memo(MyAssetContainer);
