import {
  AccountQueryKey,
  AllowancesQueryKey,
  CryptoQueryKey,
  FiatQueryKey,
  GeneratorQueryKey,
  SwapQueryKey,
  SwapType,
  UserQueryKey,
  WSBalanceType,
  WSCryptoDeposit,
  WSOTCType,
  WSPaybackType,
  WSSwapType,
} from '@bilira-org/react-utils';
import { getI18n } from 'react-i18next';

import { useSwapStore } from '@/store/swapStore';
import { queryCache } from '@Libs/clientInstances/queryClient';
import { handleNotification, handleOTCNotification } from '@Libs/ws/helpers';

enum StatusEnum {
  'requested' = 'requested',
  'finalized' = 'finalized',
  'completed' = 'completed',
  'canceled' = 'canceled',
}

enum OTCStatusEnum {
  'created' = 'pending',
  'completed' = 'completed',
  'accepted' = 'accepted',
}

export const handleFiatDeposit = (data: any) => {
  if (data.status === StatusEnum.completed) {
    handleNotification(data.notification, data.status, 'fiat:deposit');
  }

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION]);
  queryCache.resetQueries([FiatQueryKey.TRANSACTIONS]);
};

export const handleFiatWithdrawal = (data: any) => {
  if (data.status === StatusEnum.requested || data.status === StatusEnum.finalized) {
    handleNotification(data.notification, data.status, 'fiat:withdrawal');
  }

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION]);
  queryCache.resetQueries([FiatQueryKey.TRANSACTIONS]);
};

export const handleCryptoDeposit = (data: WSCryptoDeposit) => {
  if (data.status === StatusEnum.completed) {
    handleNotification(data.notification, data.status, 'crypto:deposit');
  }

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION]);
  queryCache.resetQueries([CryptoQueryKey.TRANSACTIONS]);
};

export const handleCryptoWithdrawal = (data: any) => {
  if (data.status === StatusEnum.completed) {
    handleNotification(data.notification, data.status, 'crypto:withdrawal');
  }

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION]);
  queryCache.resetQueries([CryptoQueryKey.TRANSACTIONS]);
};

export const handlePromo = (data: any) => {
  handleNotification(data.notification, data.status, 'other:promo');

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_BALANCES]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_TOTAL_BALANCE]);

  queryCache.resetQueries([GeneratorQueryKey.BALANCES_GENERATOR]);
  queryCache.resetQueries([GeneratorQueryKey.BALANCE_GENERATOR]);
};

export const handleAccountBalance = (data: WSBalanceType) => {
  // setQueryData([AccountQueryKey.ACCOUNT_BALANCES, data.id])(getBalanceDataBy(data));

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_BALANCES, data.id]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_TOTAL_BALANCE]);
  queryCache.resetQueries([AllowancesQueryKey.NEXT_TRANSACTION]);
  queryCache.resetQueries([AllowancesQueryKey.ALLOWANCES]);

  queryCache.resetQueries([GeneratorQueryKey.BALANCES_GENERATOR]);
  queryCache.resetQueries([GeneratorQueryKey.BALANCE_GENERATOR]);
  queryCache.resetQueries([GeneratorQueryKey.SWAP_PAIRS_BALANCE_GENERATOR]);
};

export const handleSwapTransaction = (data?: WSSwapType) => {
  if (data?.status === StatusEnum.completed) {
    handleNotification(data.notification, data.status, 'swap:swap');
    queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
    queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION]);
    queryCache.resetQueries([SwapQueryKey.TRANSACTIONS]);
  }
};
export const handleSwapQuote = (data?: SwapType) => {
  if (data?.status === StatusEnum.canceled && !data.offer_amount) {
    handleNotification(getI18n().t('market.swap.offer-fetch-failed'), data.status, 'swap:swap');
  }

  const swapOffer = useSwapStore.getState().swapOffer;

  console.debug('🫧 offer ids: ', {
    old: swapOffer?.id,
    new: data?.id,
    equal: swapOffer?.id === data?.id,
  });

  if (swapOffer?.id === data?.id) {
    useSwapStore.setState({
      swapOffer: data,
    });
  }
};

export const handleOTCTransaction = (data?: WSOTCType) => {
  const status = data?.status as OTCStatusEnum;

  if (status === OTCStatusEnum.completed || status === OTCStatusEnum.accepted || status === OTCStatusEnum.created) {
    data && handleOTCNotification(data.notification, data.status);
    queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
    queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTION, { transactionId: data?.transactionId }]);
  }

  if (status === OTCStatusEnum.completed) {
    // to update balance when  OTC transaction completed
    queryCache.resetQueries([AccountQueryKey.ACCOUNT_BALANCES]);
    queryCache.resetQueries([AccountQueryKey.ACCOUNT_TOTAL_BALANCE]);
  }

  queryCache.resetQueries([SwapQueryKey.SWAP_OTC_TRANSACTIONS]);
};

export const handlePaybackTransaction = (data: WSPaybackType) => {
  handleNotification(data.notification, StatusEnum.completed, 'other:payback');

  queryCache.resetQueries([AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS]);
  queryCache.resetQueries([UserQueryKey.REFERRAL_CODES]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_BALANCES]);
  queryCache.resetQueries([AccountQueryKey.ACCOUNT_TOTAL_BALANCE]);
};
