import { SystemBankAccountModel } from '../../network/stablecoin/model';

/**
 * Adds the "Other Banks" entry to a list of system bank accounts.
 *
 * The function concatenates the original data with the newly created "Other Banks" entry
 * and returns the combined list. If no default bank account is found, the original data is returned unmodified.
 *
 * @param {SystemBankAccountModel[] | undefined} data An array of system bank account objects.
 * @param {boolean} includeOtherBanksEntry A boolean indicating whether to include the "Other Banks" entry. Default is true.
 * @returns {SystemBankAccountModel[] | undefined} The updated array of system bank account
 */
function addOtherBanksEntry(data?: SystemBankAccountModel[], includeOtherBanksEntry: boolean = true) {
  if (!includeOtherBanksEntry || !data) {
    return data;
  }

  const defaultBank = data.find((bank) => bank.is_default);
  const otherBank = defaultBank
    ? ({
        bank_name: 'Other bank',
        alias: 'other',
        iban: defaultBank.iban,
        is_default: true,
        active: true,
        bank_code: 'other',
        id: 'other-bank',
      } as SystemBankAccountModel)
    : undefined;

  if (otherBank) {
    return data.concat(otherBank);
  }

  return data;
}

export default addOtherBanksEntry;
