import BigNumber from 'bignumber.js';

import { AssetPairPriceType } from '../../network/stablecoin/model';
import { getFormattedNumberByPrice } from '../formatNumberByPrice';

interface Props {
  /** Amount used to calculate asset price  */
  amount: string;
  /** Asset pair price data */
  assetPairPrice: AssetPairPriceType;
  /** Symbol used for equality indication */
  equalitySymbol: string;
  /** Locale to format unit price  */
  locale: string;
}

/**
 * Creates the unit price info string of an asset based on the provided amount and asset pair price information.
 * Automatically switches unit price assets based on the asset precedence. (Stablecoins will be on the right side)
 *
 * @param {Object} props - The properties object.
 * @returns {string} The formatted unit price information.
 *
 * @example
 * const assetPairPrice = {
 *   base_asset: 'BTC',
 *   quote_asset: 'USDT',
 *   price: '40000'
 * };
 * getAssetUnitPriceInfo({
 *   amount: '2',
 *   assetPairPrice: assetPairPrice,
 *   equalitySymbol: '=',
 *   locale: 'en'
 * });
 * // "1 BTC = 20000.00 USDT"
 * // * * * * * * * * * * * * * *
 * const assetPairPrice = {
 *   base_asset: 'USDT',
 *   quote_asset: 'ETH',
 *   price: '0.00027027027'
 * };
 * getAssetUnitPriceInfo({
 *   amount: '1',
 *   assetPairPrice: assetPairPrice,
 *   equalitySymbol: '=',
 *   locale: 'en'
 * });
 * // "1 ETH = 3700.00 USDT"
 */
export function getAssetUnitPriceInfo({ amount, assetPairPrice, equalitySymbol, locale }: Props) {
  const switchAsset = getAssetPrecedence(assetPairPrice.base_asset) > getAssetPrecedence(assetPairPrice.quote_asset);

  const [dividend, divider] = switchAsset ? [amount, assetPairPrice.price] : [assetPairPrice.price, amount];

  const price = divider !== '0' ? BigNumber(dividend).dividedBy(divider).toString() : '0';

  const formattedPrice = getFormattedNumberByPrice({
    price: price,
    value: price,
    locale: locale,
    type: 'price',
  });

  return `1 ${
    switchAsset ? assetPairPrice.quote_asset : assetPairPrice.base_asset
  } ${equalitySymbol} ${formattedPrice} ${switchAsset ? assetPairPrice.base_asset : assetPairPrice.quote_asset}`;
}

/**
 * Returns the precedence value of a given asset to to determine its position in a unit price calculation..
 *
 * The precedence value is used to determine the order of assets in a pair.
 * Higher precedence values indicate higher priority to switch the asset to the right side of the unit price.
 *
 * @param {string} asset - The asset symbol for which to get the precedence.
 * @returns {number} The precedence value of the asset.
 *
 * @example
 * getAssetPrecedence('TRYB'); // 2
 */
function getAssetPrecedence(asset: string) {
  switch (asset) {
    case 'TRYB':
      return 2;
    case 'USDT':
    case 'USDC':
    case 'USDY':
      return 1;
    default:
      return 0;
  }
}
