import React, { useCallback } from 'react';

import { KeyValuePairType } from '@bilira-org/react-utils';

import { useSessionStore } from '@/store';
import { useGeneralStore } from '@/store/generalStore';
import LanguageModal from '@Components/settings/language/LanguageModal';
import PreferencesApi from '@Libs/clientInstances/preferencesQuery';

/**
 * Modal for handling user's language preference.
 * Utilizes general store to control whether modal is active or not.
 */
function LanguagePreferenceModal() {
  const { languageModalActive, setLanguageModalActive } = useGeneralStore();
  const isLoggedIn = useSessionStore((state) => state.isLoggedIn);

  const { mutateAsync, isPending } = PreferencesApi.usePutUserPreference();

  const handleChange = useCallback(async (data: KeyValuePairType) => {
    return mutateAsync(data);
  }, []);

  return (
    <>
      <LanguageModal
        modalActive={languageModalActive}
        setModalActive={setLanguageModalActive}
        callback={isLoggedIn ? handleChange : undefined}
        isMutating={isPending}
      />
    </>
  );
}

export default LanguagePreferenceModal;
