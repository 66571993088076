import { RewardsTasksType, RewardTaskActionType, UserParticipationType } from '../../network/stablecoin/model';

const hiddenActionTypesWhenCompleted: RewardTaskActionType[] = ['kyc1'];

/**
 * Determines whether action button should be hidden or not based on the task's properties.
 *
 * @param {UserParticipationType | RewardsTasksType} task - The task object, which can either be a user participation task or a rewards task.
 * @returns {RewardTaskState} - Returns boolean, whether action button should be hidden or not:
 * - true: The action should be hidden.
 * - false: The action should be visible.
 */
export const shouldHideRewardAction = (task: UserParticipationType | RewardsTasksType): boolean => {
  if ('status' in task) {
    if (task.status === 'completed' && hiddenActionTypesWhenCompleted.includes(task.action_type)) {
      return true;
    }
  }

  return false;
};
