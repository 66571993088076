import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../../client';
import { QueryOptions, ResultType } from '../../../types';
import { ISwapApi, SwapApi } from '../api';
import { SwapQueryKey } from '../constants';
import {
  OTCTransactionPatchType,
  OTCTransactionsParamsType,
  OTCTransactionsType,
  OTCType,
  TransactionsRecordType,
} from '../model';
import {
  PostSwapQuoteType,
  SavedCommissionsType,
  SwapBodyType,
  SwapCommissionTiersItemType,
  SwapCommissionTierType,
  SwapConfirmBodyType,
  SwapPairType,
  SwapResponseType,
} from '../model/SwapTypes';
import { SwapTransactionsParamsType, SwapTransactionsType } from '../model/Transaction';

class SwapQuery {
  _api: ISwapApi;

  public constructor(params: HttpClientType) {
    this._api = new SwapApi(params);
  }

  public usePostSwapQuoteRequest = (options?: QueryOptions) => {
    const controller = new AbortController();

    const mutate = useMutation<PostSwapQuoteType, HttpErrorType, SwapBodyType>({
      mutationFn: async (body) => {
        return this._api.postSwapQuoteRequest(body, controller);
      },
      ...options,
    });

    return { ...mutate, cancelRequest: () => controller.abort() };
  };

  public useGetSwapPairs = (options?: QueryOptions) => {
    return useQuery<SwapPairType[], HttpErrorType>({
      queryKey: [SwapQueryKey.SWAP_PAIRS],
      queryFn: () => this._api.getSwapPairs(),
      gcTime: 50000,
      staleTime: 50000,
      ...options,
    });
  };

  public usePatchSwapQuoteConfirm = (options?: QueryOptions) => {
    return useMutation<SwapResponseType, HttpErrorType, SwapConfirmBodyType>({
      mutationFn: this._api.patchSwapQuoteConfirm,
      ...options,
    });
  };

  public useGetTransaction = (
    params: {
      id: string;
      account: string;
    },
    options?: QueryOptions,
  ) =>
    useQuery<TransactionsRecordType>({
      queryKey: [SwapQueryKey.TRANSACTION, params],
      queryFn: () => this._api.getTransaction(params.id, params.account),
      ...options,
    });

  public useGetOtcTransactions = (params: OTCTransactionsParamsType, options?: QueryOptions) =>
    useQuery<OTCTransactionsType>({
      queryKey: [SwapQueryKey.SWAP_OTC_TRANSACTIONS, params],
      queryFn: () => this._api.getOtcTransactions(params),
      placeholderData: keepPreviousData,
      ...options,
    });

  public useGetOtcTransactionDetail = (id: string, options?: QueryOptions) =>
    useQuery<OTCType>({
      queryKey: [SwapQueryKey.SWAP_OTC_TRANSACTION_DETAIL, id],
      queryFn: () => this._api.getOtcTransactionDetail(id),
      ...options,
    });

  public usePatchOTCTransaction = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<
      ResultType,
      HttpErrorType,
      {
        id: string;
        body: Partial<OTCTransactionPatchType>;
      }
    >({
      mutationFn: this._api.patchOtcTransaction,
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [SwapQueryKey.SWAP_OTC_TRANSACTIONS],
        }),
      ...options,
    });
  };

  public useGetSavedCommissions = (options?: QueryOptions) =>
    useQuery<SavedCommissionsType>({
      queryKey: [SwapQueryKey.SAVED_COMMISSIONS],
      queryFn: () => this._api.getSavedCommissions(),
      ...options,
    });

  public useGetSwapCommissionTier = (options?: QueryOptions) =>
    useQuery<SwapCommissionTierType>({
      queryKey: [SwapQueryKey.SWAP_COMMISSION_TIER],
      queryFn: () => this._api.getSwapCommissionTier(),
      ...options,
    });

  public useGetSwapCommissionTiers = (options?: QueryOptions) =>
    useQuery<SwapCommissionTiersItemType[]>({
      queryKey: [SwapQueryKey.SWAP_COMMISSION_TIERS],
      queryFn: () => this._api.getSwapCommissionTiers(),
      ...options,
    });

  public useGetTransactions = (params: SwapTransactionsParamsType, options?: QueryOptions) =>
    useQuery<SwapTransactionsType>({
      queryKey: [SwapQueryKey.TRANSACTIONS, params],
      queryFn: () => this._api.getTransactions(params),
      placeholderData: keepPreviousData,
      ...options,
    });
}

export default SwapQuery;
