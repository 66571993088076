import React, { useState } from 'react';

import { Flex, Hidden, Icon, Modal, Text } from '@bilira-org/design';
import { SeasonsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import SeasonTimer from '../seasonTimer/SeasonTimer';

type Props = {
  isSeasonsPending?: boolean;
  seasons?: SeasonsType;
};

const SeasonTabInfo = ({ isSeasonsPending, seasons }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Hidden show={{ xs: false, sm: true }}>
        {!isSeasonsPending && <SeasonTimer season={seasons} key={seasons?.current_season?.id} />}
      </Hidden>
      <Hidden show={{ xs: true, sm: false }}>
        <Flex direction="row" minHeight="full" items="center">
          <Icon
            size="sm"
            overlaySize="4xl"
            type="o:information-circle"
            color="neutral-900"
            overlayColor="neutral-300"
            onClick={() => setIsModalOpen(true)}
          />
          <Modal title={t('rewards.about-reward-center')} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Text size="sm">{t('rewards.reward-info')}</Text>
            <Text weight="semibold" mt="lg" mb="sm" color="secondary-500">
              {t('rewards.remaining-time')}
            </Text>
            {!isSeasonsPending && <SeasonTimer color="primary/4" season={seasons} key={seasons?.current_season?.id} />}
          </Modal>
        </Flex>
      </Hidden>
    </>
  );
};

export default SeasonTabInfo;
