import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../../client';
import { QueryOptions, ResultType } from '../../../types';
import { FiatApi, IFiatApi } from '../api';
import { AccountQueryKey, FiatQueryKey } from '../constants';
import { FeeType, SystemBankAccountModel, TransactionsRecordType } from '../model';
import { FiatTransactionsParamsType, FiatTransactionsType } from '../model/Transaction';

class FiatQuery {
  _api: IFiatApi;

  public constructor(params: HttpClientType) {
    this._api = new FiatApi(params);
  }

  public useGetWithdrawalFee = (amount: string, options?: QueryOptions) =>
    useQuery<FeeType, HttpErrorType>({
      queryKey: [FiatQueryKey.WITHDRAWAL_FEE, amount],
      queryFn: () => this._api.getWithdrawalFee(amount),
      ...options,
    });
  public useDeleteWithdrawals = (options?: QueryOptions) => {
    const queryClient = useQueryClient();

    return useMutation<ResultType, HttpErrorType, string>({
      mutationFn: this._api.deleteWithdrawals,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [AccountQueryKey.ACCOUNT_ALL_TRANSACTIONS] });
      },
      ...options,
    });
  };

  public useGetAccounts = (options?: QueryOptions) => {
    return useQuery<SystemBankAccountModel[]>({
      queryKey: [FiatQueryKey.SYSTEM_BANK_ACCOUNTS],
      queryFn: () => this._api.getAccounts(),
      ...options,
    });
  };

  public useGetTransaction = (params: { id: string; account: string }, options?: QueryOptions) =>
    useQuery<TransactionsRecordType>({
      queryKey: [FiatQueryKey.TRANSACTION, params.id, params.account],
      queryFn: () => this._api.getTransaction(params.id, params.account),
      ...options,
    });

  public useGetTransactions = (params: FiatTransactionsParamsType, options?: QueryOptions) =>
    useQuery<FiatTransactionsType>({
      queryKey: [FiatQueryKey.TRANSACTIONS, params],
      queryFn: () => this._api.getTransactions(params),
      placeholderData: keepPreviousData,
      ...options,
    });
}

export default FiatQuery;
