import BigNumber from 'bignumber.js';

/**
 * Gets the length of the integer part of a number.
 * If the integer part is zero, it returns 0.
 *
 * @param {BigNumber | number | string} value - The number whose integer length is to be calculated.
 * @returns {number} The length of the integer part of the BigNumber. Returns 0 if the integer part is zero.
 *
 * @example
 * getIntegerLength('1230.45678') // 4
 * getIntegerLength('0.123') // 0
 * getIntegerLength('1.123') // 1
 */
export const getIntegerLength = (value: BigNumber | number | string) => {
  const integerPart = BigNumber(value).integerValue().toString();
  if (integerPart === '0') {
    return 0;
  } else return integerPart.length;
};
