import { Form } from '@bilira-org/design';
import { getErrorMessage, TwoFAOptionsType, WalletModel } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import AccountApi from '@Libs/clientInstances/accountQuery';
import useNotify from '@Libs/hooks/useNotify';
import useAuth from '@Libs/hooks/userAuth';
import useTwoFA from '@Libs/hooks/useTwoFA';

type CreateWallet = {
  onFinish: () => void;
  initialValues: Partial<WalletModel>;
};

const useCreateWallet = ({ onFinish, initialValues }: CreateWallet) => {
  const twoFAType: TwoFAOptionsType | undefined = initialValues.id ? undefined : 'addCryptoWallet';
  const { t } = useTranslation();
  const { account } = useAuth();
  const { twoFAData, activeTwoFAModal, setActiveTwoFAModal, handleVerifyTwoFA, handleSave } =
    useTwoFA<WalletModel>(twoFAType);

  const post = AccountApi.usePostCryptoWallet();
  const patch = AccountApi.usePatchCryptoWallet();

  const form = Form.useForm<WalletModel>({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useNotify({
    isSuccess: post.isSuccess || patch.isSuccess,
    isError: post.isError || patch.isError,
    successMsg: t('common.saved'),
    errorMsg: getErrorMessage(post.error || patch.error, t('common.could-not-save')) as string,
    onComplete: onFinish,
  });

  const onSave = ({ id, ...formValues }: WalletModel, verification?: string) => {
    if (id) {
      patch.mutate({
        id: id,
        body: { alias: formValues.alias },
      });
    } else {
      post.mutate({
        id: account,
        body: {
          network: formValues.network,
          asset: formValues.asset,
          alias: formValues.alias,
          address: formValues.address,
          memo: formValues.memo,
          two_fa_token: verification,
        },
      });
    }
  };

  const onSubmit = (payload: WalletModel) => handleSave(onSave, payload);
  const onVerifyTwoFA = (values: string) => handleVerifyTwoFA(onSave, values);

  return { onSubmit, form, twoFAData, activeTwoFAModal, setActiveTwoFAModal, onVerifyTwoFA, twoFAType };
};

export default useCreateWallet;
