import { Form, notification } from '@bilira-org/design';
import { BankAccountType, TwoFAOptionsType } from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import { handleErrorResponse } from '@/libs/utils';
import AccountQuery from '@Libs/clientInstances/accountQuery';
import useAuth from '@Libs/hooks/userAuth';
import useTwoFA from '@Libs/hooks/useTwoFA';

interface ICreateBankAccount {
  /** Callback to be called when bank account created/updated */
  callback: () => void;
  /**
   * Bank account to edit
   * If not provided, component will work in create mode
   */
  initialValues?: BankAccountType;
}

/**
 * Custom hook to handle create and edit bank features.
 * If initialValues are provided hook will be in edit mode, otherwise in create mode.
 *
 * @returns Form handlers and request states
 */
const useCreateBankAccounts = ({ callback, initialValues }: ICreateBankAccount) => {
  const twoFAType: TwoFAOptionsType | undefined = initialValues?.id ? 'updateBankAccount' : undefined;
  const { t } = useTranslation();
  const { account } = useAuth();
  const post = AccountQuery.usePostBankAccount();
  const patch = AccountQuery.usePatchBankAccount();

  const { twoFAData, activeTwoFAModal, setActiveTwoFAModal, handleVerifyTwoFA, handleSave } =
    useTwoFA<BankAccountType>(twoFAType);

  const form = Form.useForm({
    defaultValues: initialValues,
    mode: 'onChange',
  });

  /**
   * Callback function to handle form submission.
   *
   * @param {BankAccountType} values - Form values.
   * @param {string} [token] - Two-factor authentication token.
   * @returns {void}
   */
  const onFinish = ({ id, ...rest }: BankAccountType, token?: string) => {
    const isUpdate = Boolean(id);
    const states = {
      update: { loading: t('common.updating'), success: t('common.updated'), error: t('common.could-not-update') },
      save: { loading: t('common.saving'), success: t('common.saved'), error: t('common.could-not-save') },
    }[isUpdate ? 'update' : 'save'];

    const loadingToastId = notification.loading(t(states.loading));

    const handleSuccess = () => {
      notification.remove(loadingToastId);
      notification.success(states.success);
      form.reset();
      callback();
    };

    const handleError = (e: any) => {
      notification.remove(loadingToastId);
      handleErrorResponse(e, states.error);
    };

    if (!isUpdate) {
      post
        .mutateAsync({
          id: account,
          body: {
            alias: rest.alias,
            iban: rest.iban,
            is_default: false,
          },
        })
        .then(handleSuccess)
        .catch(handleError);
    } else {
      patch
        .mutateAsync({
          id: id,
          body: { alias: rest.alias, is_default: rest.is_default, two_fa_token: token },
        })
        .then(handleSuccess)
        .catch(handleError);
    }
  };

  /**
   * Function to handle form submission.
   *
   * @param {BankAccountType} payload - Form values.
   * @returns {void}
   */
  const onSubmit = (payload: BankAccountType) => handleSave(onFinish, payload);

  /**
   * Function to handle the verification of two-factor authentication.
   *
   * @param {string} values - Two-factor authentication values.
   * @returns {void}
   */
  const onVerifyTwoFA = (values: string) => handleVerifyTwoFA(onFinish, values);

  return {
    form,
    onSubmit,
    isPending: post.isPending,
    isSuccess: post.isSuccess,
    error: post.error,
    twoFAData,
    activeTwoFAModal,
    setActiveTwoFAModal,
    onVerifyTwoFA,
    twoFAType,
  };
};

export default useCreateBankAccounts;
