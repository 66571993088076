import { useCallback } from 'react';

import { CoinSymbolType, concatAssetPair } from '@bilira-org/react-utils';
import { useLocation, useNavigate } from 'react-router-dom';

import { env } from '@Libs/constants/env';
import useLocaleRoute from '@Libs/hooks/useLocaleRoute';

/**
 * Hook for navigating to a market page or widget page with a specified asset pair.
 * @returns Callback function for navigating to a market or widget.
 *
 * @example
 * // Usage of useNavigateToMarket hook
 * const navigateToMarket = useNavigateToMarket();
 * navigateToMarket({ symbol: 'ETH', baseAsset: 'USDT', direction: 'buy' });
 */
export default function useNavigateToMarket() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getRoute } = useLocaleRoute();

  const callback = useCallback(
    ({
      base,
      quote = 'TRYB',
      direction = 'buy',
    }: {
      base: CoinSymbolType;
      quote?: CoinSymbolType;
      direction?: 'buy' | 'sell';
    }) => {
      if (base === 'BMMF') {
        return;
      }

      const { assetPair, swapPair } = concatAssetPair(base, quote, direction);

      if (location.pathname.includes('widget')) {
        // Open a new window with the URL if called from widget page.
        window.open(`${env.STABLECOIN_WEB_URL}/widget?pair=${assetPair}`, '_blank');
        return;
      }

      console.log(base, quote);

      navigate(`${getRoute('Market')}/${assetPair}`, { state: { swapPair: swapPair } });
    },
    [getRoute, location, navigate],
  );

  return callback;
}
