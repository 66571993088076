import { IconCustomType } from '@bilira-org/design';
import { AssetPairType, KeyValuePairType, NetworkAliasType } from '@bilira-org/react-utils';

export const LANGUAGES = [
  { key: 'tr', value: 'Türkçe' },
  { key: 'en', value: 'English' },
] as KeyValuePairType[];

export enum LEGAL_STATUS {
  NATIVE_TC_CITIZEN = 'native_tc_citizen',
  FOREIGNER_TC_CITIZEN = 'foreigner_tc_citizen',
  BLUE_CARD = 'blue_card',
  KKTC_CITIZEN = 'kktc_citizen',
  CORPORATE = 'corporate',
}

export const SWAP_RETRY_TIMES = 2;

export const SWAP_COMPLETED_HASH = '#sc';

export const DEFAULT_SWAP_PAIR = 'ETH_TRYB_buy';
export const DEFAULT_BUY_SELL_PAIR = 'USDC_TRYB';

export const USER_TIER_ICONS: Record<string, IconCustomType> = {
  standard: 'diamond-green',
  bronze: 'diamond-bronze',
  silver: 'diamond-grey2',
  gold: 'diamond-gold',
  platinum: 'diamond-grey1',
};

export const WORK_HOURS = {
  start: '09:30',
  end: '17:30',
};

export const HIDE_SWAP_WIDGET_ON_PAIRS: AssetPairType[] = ['TRYB_TRY'];

export const REMOTE_CONFIG_KEYS = {
  REFERRAL: 'referral_enabled',
  NOTIFICATION_PREFERENCES: 'notification_preferences_enabled',
} as const;
