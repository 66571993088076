import React, { useRef, useState } from 'react';

import {
  Alert,
  Block,
  Button,
  CircleTimer,
  ICircleTimerReturn,
  Input,
  Label,
  Link,
  notification,
  Text,
} from '@bilira-org/design';
import { TwoFAMethodType, TwoFAOptionsType } from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';

import UserQuery from '@Libs/clientInstances/userQuery';
import { handleErrorResponse } from '@Libs/utils';

type InputOTPProps = {
  /** Label of the input */
  label: string;
  /** Info message about input */
  info: string;
  /** Action type to be verified with 2fa (e.g. 'addCryptoWallet', 'fiatWithdrawal') */
  type: TwoFAOptionsType;
  /** Method of the two-factor authentication (e.g., 'email', 'sms') */
  method: TwoFAMethodType;
  /** Function to be called with token after 2fa verified */
  callback: (code: string) => void;
  /** Validation message to be displayed */
  validationMessage?: string;
  /** Additional payload to be sent with 2fa request */
  additionalPayload?: Record<string, string | undefined>;
  /** Function to be called when "Enter" key is pressed */
  onEnter?: () => void;
  /** Value of the input */
  value: string;
  /** Function to reset the validation message */
  resetValidationMessage: () => void;
};

/**
 * Input field for entering OTP codes for 2FA methods (Email, SMS)
 */
const InputOTP = ({
  validationMessage,
  callback,
  label,
  info,
  type,
  method,
  additionalPayload,
  onEnter,
  value,
  resetValidationMessage,
}: InputOTPProps) => {
  const { t } = useTranslation();
  const circleTimerRef = useRef<ICircleTimerReturn>();
  const [timeout, setTimeout] = useState<boolean>(true);
  const { mutateAsync } = UserQuery.useGetTwoFACode();

  const resend = () => {
    setTimeout(false);

    mutateAsync({ type, method, additional_payload: additionalPayload })
      .then(() => {
        circleTimerRef.current?.reset(true);
        onChange('');
        resetValidationMessage();

        notification.success(t('two-fa.method-sent', { method: t(`two-fa.methods.${method}`) }));
      })
      .catch((error) => {
        setTimeout(true);
        handleErrorResponse(error, t('two-fa.error-sending-code'));
      });
  };

  const onChange = (value: string) => {
    callback(value);
  };

  const hasRunOutOfAttempts = validationMessage?.match(/0 attempts/);

  return (
    <Block>
      <Label>{label}</Label>
      <Input.Number
        disableZeroCheck
        value={value}
        onChange={onChange}
        disabled={timeout}
        iconEnd={
          timeout ? (
            <Button shine size="sm" variant="filled-translucent" onClick={resend}>
              {t('two-fa.send-code')}
            </Button>
          ) : (
            <Text size="sm" color="neutral-600">
              <CircleTimer
                variant="countdown"
                updateInterval={1000}
                enabled={false}
                my="3xl"
                ref={circleTimerRef}
                duration={120}
                onComplete={() => {
                  setTimeout(true);
                }}
              />
            </Text>
          )
        }
        onEnter={onEnter}
      />
      {validationMessage && (
        <Text color="red-500" size="sm">
          {validationMessage}
        </Text>
      )}
      {hasRunOutOfAttempts && (
        <Alert status="red" my="sm">
          <Trans
            t={t}
            i18nKey="two-fa.run-out-of-attempts"
            components={{
              anchor: <Link color="primary-500" anchor href="mailto:support@bilira.co" />,
            }}
          />
        </Alert>
      )}
      <Text color="neutral-700" size="sm" mt="sm">
        {info}
      </Text>
    </Block>
  );
};
export default InputOTP;
