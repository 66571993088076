import React from 'react';

import { Block, Dropdown, Flex, Input, List, Popover } from '@bilira-org/design';
import { CoinSymbolType } from '@bilira-org/react-utils';

type Props = {
  asset?: string;
  assets?: CoinSymbolType[];
  isPending?: boolean;
  callback: (value: CoinSymbolType) => void;
  amount?: string;
  onAmountChange: (value: string) => void;
};

const AssetList: React.FC<Props> = ({ asset, assets, isPending, callback, amount, onAmountChange }) => {
  if (isPending) {
    return 'loading';
  }

  return (
    <Flex direction="row" gap="2xl" items="center" justify="evenly" width="full">
      <Flex.Item width={{ xs: '250', md: '3/4' }}>
        <Input.Number value={amount} onChange={onAmountChange} />
      </Flex.Item>
      <Dropdown
        position="right"
        value={asset}
        color="radial-primary"
        size="md"
        textSize="lg"
        dataSource={assets}
        onSelect={(item) => callback(item)}
        justify="between"
        renderItem={(item) => <Dropdown.Item key={item}>{item}</Dropdown.Item>}
        listSize="full"
      />
    </Flex>
  );
};

export default AssetList;
