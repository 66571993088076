import { IFiatApi } from './interface';
import { HttpClient, HttpClientType, HttpErrorType } from '../../../client';
import { ResultType } from '../../../types';
import { FeeType, SystemBankAccountModel, TransactionsRecordType } from '../model';
import { FiatTransactionsParamsType, FiatTransactionsType } from '../model/Transaction';

class FiatApi extends HttpClient implements IFiatApi {
  public constructor(conf: HttpClientType) {
    super(conf);
  }

  public getWithdrawalFee = (amount: string) =>
    this.instance.get<HttpErrorType, FeeType>(`/fiat/withdrawals/fee`, { params: { amount } });

  public getAccounts = () => {
    return this.instance.get<string, SystemBankAccountModel[]>(`/fiat/system-bank-accounts`);
  };
  /**
   * DELETE
   * /fiat/withdrawals/{id}
   * User requests to cancel crypto withdrawal.
   * @param id
   */
  public deleteWithdrawals = (id: string) => {
    return this.instance.delete<HttpErrorType, ResultType, string>(`/fiat/withdrawals/${id}`);
  };
  public getTransaction = (id: string, account: string) => {
    return this.instance.get<HttpErrorType, TransactionsRecordType>(`/fiat/transactions/${id}?account=${account}`);
  };

  public getTransactions = (params: FiatTransactionsParamsType) => {
    return this.instance.get<HttpErrorType, FiatTransactionsType>(`/fiat/transactions/`, {
      params,
    });
  };
}

export default FiatApi;
