import React, { memo } from 'react';

import { Block, Skeleton, Span, Text } from '@bilira-org/design';
import {
  CryptoAssetsType,
  DirectionType,
  DomainType,
  getFormattedAllowanceFields,
  WalletModel,
} from '@bilira-org/react-utils';
import { useTranslation } from 'react-i18next';

import allowancesQuery from '@Libs/clientInstances/allowancesQuery';
import useAuth from '@Libs/hooks/userAuth';

type Props = {
  /**
   * Domain for the limits
   */
  domain: DomainType;
  /** Direction for the limits */
  direction: DirectionType;
  /**
   * Wallet for the limits
   * Not required on "fiat" domain
   */
  wallet?: WalletModel;
  /**
   * Asset for the limits
   * Not required on "fiat" domain
   */
  asset?: CryptoAssetsType;
};

/**
 * Limits component displays daily and monthly limits for a given domain, direction, wallet, and asset.
 *
 * @example
 *  <Limits domain="crypto" direction="deposit" wallet={wallet} asset="ETH" />
 *  <Limits domain="fiat" direction="deposit"/>
 *
 */
const Limits = ({ domain, direction, wallet, asset }: Props) => {
  const { t, i18n } = useTranslation();
  const { account } = useAuth();
  const isQueryInvalid = domain === 'crypto' && (asset === undefined || wallet === undefined);

  const { data: allowance, isPending } = allowancesQuery.useGetAllowances(
    {
      account,
      domain: domain,
      direction: direction,
      asset: asset?.symbol,
      network: wallet?.network,
    },
    { enabled: !isQueryInvalid, staleTime: undefined },
  );

  const { dailyAllowed, dailyRemaining, monthlyAllowed, monthlyRemaining } = getFormattedAllowanceFields(
    allowance,
    i18n.language,
  );
  const isCryptoAllowance = asset?.symbol !== undefined;
  const currencySymbol = isCryptoAllowance ? 'TRYB' : 'TL';

  if (isQueryInvalid) {
    return <LimitsInvalid />;
  }

  if (isPending) {
    return <LimitsSkeleton />;
  }

  return (
    <Block>
      <Text size="sm">{t('common.remaining-withdrawal-limit')}</Text>
      <Block gap="sm">
        <Text size="sm" weight="medium">
          <Span color="primary-500">
            {dailyRemaining} {currencySymbol}
          </Span>{' '}
          / {dailyAllowed} {currencySymbol} ({t('common.daily')})
        </Text>
        <Text size="sm" weight="medium">
          <Span color="primary-500">
            {monthlyRemaining} {currencySymbol}
          </Span>{' '}
          / {monthlyAllowed} {currencySymbol} ({t('common.monthly')})
        </Text>
      </Block>
    </Block>
  );
};

export default Limits;

const LimitsSkeleton = () => {
  return (
    <Block row justify="between">
      <Skeleton height="size-5" width="size-10" />
      <Block gap="sm" items="end">
        <Skeleton height="size-5" width="size-20" />
        <Skeleton height="size-5" width="size-16" />
      </Block>
    </Block>
  );
};

const LimitsInvalid = memo(() => {
  const { t } = useTranslation();
  return (
    <Block>
      <Text size="sm">{t('common.remaining-withdrawal-limit')}</Text>
      <Block gap="sm">
        <Text size="sm" weight="medium">
          <Span color="primary-500">-</Span> / - ({t('common.daily')})
        </Text>
        <Text size="sm" weight="medium">
          <Span color="primary-500">-</Span> / - ({t('common.monthly')})
        </Text>
      </Block>
    </Block>
  );
});
