import { useMutation } from '@tanstack/react-query';

import { HttpClientType, HttpErrorType } from '../../../client';
import { QueryOptions } from '../../../types';
import { IMarketingApi, MarketingApi } from '../api';
import { PostPromoCodeBodyType, PostPromoCodeResultType } from '../model/Marketing';

class MarketingQuery {
  _api: IMarketingApi;

  public constructor(params: HttpClientType) {
    this._api = new MarketingApi(params);
  }

  public usePostPromoCode = (options?: QueryOptions) => {
    return useMutation<PostPromoCodeResultType, HttpErrorType, PostPromoCodeBodyType>({
      mutationFn: this._api.postPromoCode,
      ...options,
    });
  };
}

export default MarketingQuery;
