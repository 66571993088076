import BigNumber from 'bignumber.js';

import { LastPriceType } from '../../../price';
import { BalanceType } from '../../model';

const updateBalancePrices = (data: BalanceType[], lastPrice: LastPriceType) => {
  const cloneBalanceList = JSON.parse(JSON.stringify(data)) as BalanceType[];

  const clonedPrice = cloneBalanceList.find((balance) => balance.asset + balance.quote_asset === lastPrice.s);
  if (!clonedPrice) {
    return data;
  }

  clonedPrice.unit_price = lastPrice.p;
  clonedPrice.price = BigNumber(clonedPrice.balance).times(lastPrice.p).toString();

  return cloneBalanceList;
};

export default updateBalancePrices;
