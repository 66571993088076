import { useEffect, useState } from 'react';

import { CoinSymbolType, concatTicker } from '@bilira-org/react-utils';

import { BuySellCoinType } from '@Components/trade/types';
import useSubscriptionHandler from '@Libs/hooks/useSubscriptionHandler';

const useAssetPair = (
  defaultBase: CoinSymbolType,
  defaultQuote: CoinSymbolType,
  baseList: CoinSymbolType[],
  quoteList: CoinSymbolType[],
) => {
  const [pairs, setPairs] = useState<Record<BuySellCoinType, CoinSymbolType>>({
    base: defaultBase,
    quote: defaultQuote,
  });

  const { subscribe, unsubscribe } = useSubscriptionHandler('convert');

  useEffect(() => {
    subscribe([concatTicker(pairs.base, pairs.quote)], { type: 'last_price' });

    return () => {
      unsubscribe([concatTicker(pairs.base, pairs.quote)], { type: 'last_price' });
    };
  }, [pairs]);

  const onAssetChange = (value: CoinSymbolType, direction: BuySellCoinType) => {
    unsubscribe([concatTicker(pairs.base, pairs.quote)], { type: 'last_price' });

    setPairs((state) => {
      const updatedPairs = { ...state, [direction]: value };

      if (direction === 'base' && !quoteList.includes(updatedPairs.quote)) {
        updatedPairs.quote = quoteList[0];
      }

      if (direction === 'quote' && !baseList.includes(updatedPairs.base)) {
        updatedPairs.base = baseList[0];
      }

      subscribe([concatTicker(updatedPairs.base, updatedPairs.quote)], { type: 'last_price' });

      return updatedPairs;
    });
  };

  return { pairs, onAssetChange };
};

export default useAssetPair;
