import BigNumber from 'bignumber.js';

import { BalanceType } from '../../model';

const aggregateTotalBalancePrice = (data?: BalanceType[]) => {
  if (!data) {
    return { balance: '0', freeBalance: '0' };
  }

  return data.reduce(
    (agg, item) => {
      return {
        balance: BigNumber(item.balance).times(item.unit_price).plus(agg.balance).toString(),
        freeBalance: BigNumber(item.free_balance).times(item.unit_price).plus(agg.freeBalance).toString(),
      };
    },
    { balance: '0', freeBalance: '0' },
  );
};

export default aggregateTotalBalancePrice;
