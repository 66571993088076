import { FunctionComponent } from 'react';

import { LayoutSkeleton } from '@bilira-org/design/root';
import { ERROR_PAGE_STATE } from '@bilira-org/react-utils';
import { Navigate, useLocation } from 'react-router-dom';

import useCheckHealth from '@Libs/hooks/useCheckHealth';

/**
 * PrivateRoute component for handling private routes in the application.
 *
 * This component checks the user's authentication status and redirects them accordingly.
 * It renders a skeleton layout while waiting for authentication status.
 * If there's an HTTP error with status code 429 (Too Many Requests) or a network error, it redirects the user to an error page with appropriate state.
 * Otherwise it renders the provided element no matter user is authenticated or not.
 *
 * Additionally, it identifies the user for trackers if the user is authenticated.
 *
 * @example
 * <PrivateRoute element={MyComponent} />
 */
function PrivateRoute({ element }: { element: FunctionComponent<unknown> }) {
  const location = useLocation();

  const { isLoading, isServerError } = useCheckHealth();
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they log in, which is a nicer user experience
  // than dropping them off on the home page.
  if (isLoading) {
    return <LayoutSkeleton />;
  }

  // Network Error is unauthorized
  // Is Service unavailable
  // if (clientState?.isServerUnavailable) {
  if (isServerError) {
    return (
      <Navigate
        to="/error"
        state={{ from: location, errorState: ERROR_PAGE_STATE.SERVER_ERROR, authServiceDown: true }}
        replace
      />
    );
  }

  const Element = element;
  return <Element />;
}

export default PrivateRoute;
