import { AllPricesType, LastPriceType, OHLCSType, OHLCTPSType } from '../../../network/price';
import { AssetsWithPriceType, CryptoAssetsType } from '../../../network/stablecoin/model';
import { CoinSymbolType } from '../../../types';
import { concatTicker } from '../../../utils';

export const getAssetPrices = (priceList: AssetsWithPriceType[], lastPrice: LastPriceType) => {
  const clonePriceList = JSON.parse(JSON.stringify(priceList)) as AssetsWithPriceType[];

  const clonedPrice = clonePriceList.find((price) => price.ticker === lastPrice.s);
  if (!clonedPrice) {
    return priceList;
  }

  clonedPrice.price = lastPrice.p;

  return clonePriceList;
};

export const aggregateAssetWithPrice = (
  assetData: CryptoAssetsType[],
  priceData: AllPricesType[],
  quote: CoinSymbolType,
) => {
  if (!priceData || !assetData) {
    return [] as AssetsWithPriceType[];
  }

  const balancesWithQuote = assetData.reduce((agg, item) => {
    const price = priceData.find((balance) => balance.s === concatTicker(item.symbol, quote)) || { p: '0' };

    agg.push({
      ...item,
      ticker: concatTicker(item.symbol, quote),
      quote: quote,
      price: price.p,
    });
    return agg;
  }, [] as AssetsWithPriceType[]);

  return balancesWithQuote;
};

export const getAssetBy = (
  assetData: CryptoAssetsType[],
  priceData: AllPricesType[],
  base: CoinSymbolType,
  quote: CoinSymbolType,
) => {
  if (!assetData) {
    return null;
  }

  const assetItem = assetData.find((asset) => asset.symbol === base);
  const priceItem = priceData?.find((price) => price.s === concatTicker(base, quote)) || { p: '0' };

  if (!assetItem) {
    return null;
  }

  return {
    ...assetItem,
    ticker: concatTicker(base, quote),
    quote: quote,
    price: priceItem.p,
  };
};

export const updateOHLCSData = (data: OHLCSType, ohlcs: OHLCTPSType) => {
  if (data.period !== ohlcs.p || data.symbol !== ohlcs.s) {
    return data;
  }

  if (!data.timestamps?.length) {
    return data;
  }

  const clonePriceList = JSON.parse(JSON.stringify(data)) as OHLCSType;

  const lastTimestamp = clonePriceList.timestamps[clonePriceList.timestamps.length - 1];

  if (lastTimestamp === ohlcs.t) {
    clonePriceList.opens[clonePriceList.opens.length - 1] = ohlcs.o;
    clonePriceList.highs[clonePriceList.highs.length - 1] = ohlcs.h;
    clonePriceList.lows[clonePriceList.lows.length - 1] = ohlcs.l;
    clonePriceList.closes[clonePriceList.closes.length - 1] = ohlcs.c;
  } else {
    clonePriceList.opens.shift();
    clonePriceList.highs.shift();
    clonePriceList.lows.shift();
    clonePriceList.closes.shift();
    clonePriceList.timestamps.shift();

    clonePriceList.opens.push(ohlcs.o);
    clonePriceList.highs.push(ohlcs.h);
    clonePriceList.lows.push(ohlcs.l);
    clonePriceList.closes.push(ohlcs.c);
    clonePriceList.timestamps.push(ohlcs.t);
  }

  return clonePriceList;
};
