import dayjs, { OpUnitType } from 'dayjs';

import { OTCType } from '../../network/stablecoin/model';

/**
 * Function to calculate remaining time of the OTC offer.
 * @param {OTCType} offer - OTC offer.
 * @param {OpUnitType} [unit='minutes'] - Unit to return remaining time. 'minutes' by default
 * @returns Remaining time in desired unit.
 *
 * @example
 * getRemainingTime(offer); // 64 (in minutes by default)
 * getRemainingTime(offer, 'hours'); // 1
 */
const getRemainingTime = (offer: OTCType, unit: OpUnitType = 'minutes') => {
  const day1 = dayjs(offer.created_at);
  const day2 = dayjs(dayjs().toDate());
  return day2.diff(day1, unit);
};

export default getRemainingTime;
