import { useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BuySellCoinType } from '@Components/trade/types';

const usePriceUpdate = () => {
  const { i18n } = useTranslation();
  const [updatedTime, setUpdatedTime] = useState(dayjs().locale(i18n.language).format('h:mm:ss A, MMMM D, YYYY'));

  const updatePrice = (onAmountChange: (value: string, direction: BuySellCoinType) => void, amount: string) => {
    if (amount) {
      onAmountChange(amount, 'base');
      setUpdatedTime(dayjs().locale(i18n.language).format('h:mm:ss A, MMMM D, YYYY'));
    }
  };

  return { updatedTime, updatePrice };
};

export default usePriceUpdate;
