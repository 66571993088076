import React from 'react';

import { Button, Empty } from '@bilira-org/design';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from '@/store/localStorage';
import MarketPriceSubscription from '@Components/live/subscription/GeneralPriceSubscription';
import { withPrivateRoute } from '@Libs/hocs';
import MarketTable from '@Modules/market/market/table/components/MarketTable';
import useWatchlistHook from '@Modules/market/market/watchlist/hooks/useWatchlistHook';

const MarketWatchlistTableContent = () => {
  const { assetList, isPending, isFetchingNextPage, subscriptionList, fetchNextPage } = useWatchlistHook();
  const { t } = useTranslation();

  const { setLocalStore } = useLocalStorage();

  return (
    <>
      <MarketPriceSubscription
        channel="market-table-watchlist"
        baseQuoteList={subscriptionList}
        events="last_price,symbol_statistics"
      />
      <MarketTable
        isFetchingNextPage={isFetchingNextPage}
        assetList={assetList}
        isPending={isPending}
        disableSorting={true}
        empty={
          <Empty
            my="3xl"
            gap="sm"
            extra={
              <Button
                variant="filled"
                size="md"
                onClick={() => {
                  setLocalStore({ MARKET_TAB: 1 });
                }}
              >
                {t('common.add-favorites')}
              </Button>
            }
            message={t('common.no-entries-found-favorites')}
          />
        }
        fetchNextPage={fetchNextPage}
      />
    </>
  );
};

export default withPrivateRoute(MarketWatchlistTableContent);
