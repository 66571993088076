import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeadTags from '@/components/common/HeadTags';
import CryptoDepositContainer from '@Modules/crypto/deposit/CryptoDepositContainer';
import CryptoWithdrawalContainer from '@Modules/crypto/withdraw/CryptoWithdrawalContainer';

const CryptoHome = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const defaultRoute = pathname === '/crypto/withdraw';

  return (
    <>
      <HeadTags
        title={defaultRoute ? t('navigation.crypto.withdraw') : t('navigation.crypto.deposit')}
        titleSuffix="subpage"
      />

      {defaultRoute ? <CryptoWithdrawalContainer /> : <CryptoDepositContainer />}
    </>
  );
};

export default CryptoHome;
